.m14 {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 140px;
  @include mobile {
    margin-top: 100px;
  }

  &_box {
    width: 100%;
    display: flex;
    margin-bottom: 100px;
    @include mobile {
      flex-wrap: wrap;
      margin-bottom: 50px;
    }
    &--right {
      flex-direction: row-reverse;
      text-align: right;
      .m14_box__image {
        margin-right: 0;
        margin-left: 100px;
        @include mobile {
          margin-left: 0;
        }
      }
    }
    &__image {
      width: 240px;
      margin-right: 100px;
      @include mobile {
        width: 40%;
        text-align: center;
        margin-bottom: 15px;
        margin-right: 0;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    &__content {
      width: calc(100% - 340px);
      color: #000;
      @include mobile {
        width: 100%;
      }
      h3 {
        font-family: 'Gilroy', sans-serif;
        font-weight: bold;
        font-size: 42px;
        line-height: em(52, 42);
        margin-bottom: 20px;
        @include mobile {
          font-size: 32px;
          line-height: em(44, 32);
        }
      }
      p {
        font-size: 18px;
        line-height: em(24, 18);
      }
    }
  }
}
