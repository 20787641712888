.m17 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 80px;
  padding-bottom: 100px;
  @include mobile {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  &__header {
    margin-top: 100px;
    margin-bottom: 80px;
    text-align: center;
    @include mobile {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
  &__description {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: block;
    margin-bottom: 150px;
    @include mobile {
      margin-bottom: 100px;
    }
    p {
      font-family: 'Gilroy';
      font-size: 24px;
      line-height: em(32, 24);
      text-align: justify;
      margin-bottom: 30px;
      @include mobile {
        font-size: 20px;
        line-height: em(28, 20);
        margin-bottom: 20px;
      }
    }
  }
  &__parkings {
    width: 100%;
  }
  &__parking {
    width: 100%;
    margin-bottom: 70px;
    h2 {
      font-family: 'Gilroy', sans-serif;
      font-size: 38px;
      line-height: em(46, 38);
      margin-bottom: 40px;
      text-transform: uppercase;
      text-align: center;
      @include mobile {
        font-size: 26px;
        line-height: em(30, 26);
      }
    }
    img {
      width: 100%;
    }
  }
}
