.m03 {
  width: 100%;
  background-color: #141111;
  padding-top: 70px;
  padding-bottom: 70px;
  @include mobile {
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__slider {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
  .main_title {
    color: white;
  }
  &__box {
    @include mobile {
      margin-bottom: 15px;
    }
    &__image {
      width: 100%;
      height: 250px;
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      background-color: #fff;
      padding: 40px 20px;
      h2 {
        font-family: 'Gilroy', sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: em(28, 24);
        margin-bottom: 15px;
      }
      p {
        font-family: 'Helvetica Neue';
        font-size: 14px;
        line-height: em(16, 14);
        margin-bottom: 20px;
      }
      a {
        font-weight: bold;
        font-size: 14px;
        text-decoration: none;
        color: #000;
        padding-right: 18px;
        background-image: url('./images/icon-more.svg');
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: right 1px;
        transition: $transition1;
        &:hover {
          padding-right: 22px;
        }
      }
    }
  }
}
