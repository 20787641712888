////
/// VARIABLES FILE File
/// @group _variables.scss
/// @author Shyqeri Gashi
////
/*====================================
=            GRID GLOBALS            =
====================================*/
/// The total number of grid columns
/// @type Number
$grid-columns: 12;
/// The gutter of the grid
/// @type Number
$gutter: 30px;

/*=====  End of GRID GLOBALS  ======*/

/*===================================
=            TRANSITIONS            =
===================================*/
/// Google Material Design cubic bezier transition
/// @type Transition
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
/// Google Material Design cubic bezier transition slower
/// @type Transition
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);

/*=====  End of TRANSITIONS  ======*/

/*=================================
=            STRUCTURE            =
=================================*/

/*=====  End of STRUCTURE  ======*/

/*==============================
=            COLORS            =
==============================*/
$accent: #d60e2c;
/*=====  End of COLORS  ======*/
