.m11 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 100px;
  @include mobile {
    margin-top: 50px;
  }

  &_wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 20px;
    @include mobile {
      padding: 20px 15px 0;
    }
    img {
      max-width: 100%;
      object-fit: contain;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    h3 {
      font-family: 'Gilroy', sans-serif;
      font-size: 26px;
      line-height: em(32, 26);
      margin-bottom: 40px;
      text-align: center;
    }
  }
}
