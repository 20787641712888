.nGY2 {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box;
}
.nGY2 .ngy2_container {
  width: 100%;
  min-width: 100px;
  font-size: 1em;
  line-height: normal;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  visibility: visible;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
@font-face {
  font-family: ngy2_icon_font;
  src: url(font/ngy2_icon_font.woff2?64889571) format('woff2'),
    url(font/ngy2_icon_font.woff?64889571) format('woff');
  font-weight: 400;
  font-style: normal;
}
.nGY2Icon-star:before {
  content: '\e801';
}
.nGY2Icon-search:before {
  content: '\e800';
}
.nGY2Icon-star-empty:before {
  content: '\e802';
}
.nGY2Icon-video:before {
  content: '\e803';
}
.nGY2Icon-videocam:before {
  content: '\e804';
}
.nGY2Icon-picture:before {
  content: '\e805';
}
.nGY2Icon-camera:before {
  content: '\e806';
}
.nGY2Icon-camera-alt:before {
  content: '\e807';
}
.nGY2Icon-ok:before {
  content: '\e808';
}
.nGY2Icon-help:before {
  content: '\e80a';
}
.nGY2Icon-help-circled:before {
  content: '\e80b';
}
.nGY2Icon-home:before {
  content: '\e80e';
}
.nGY2Icon-link:before {
  content: '\e80f';
}
.nGY2Icon-link-ext:before {
  content: '\e810';
}
.nGY2Icon-heart:before {
  content: '\e811';
}
.nGY2Icon-ngy2_chevron-right:before {
  content: '\e812';
}
.nGY2Icon-upload:before {
  content: '\e814';
}
.nGY2Icon-reply-all:before {
  content: '\e815';
}
.nGY2Icon-export:before {
  content: '\e816';
}
.nGY2Icon-chat:before {
  content: '\e818';
}
.nGY2Icon-attention:before {
  content: '\e819';
}
.nGY2Icon-location:before {
  content: '\e81a';
}
.nGY2Icon-trash:before {
  content: '\e81b';
}
.nGY2Icon-folder-empty:before {
  content: '\e81c';
}
.nGY2Icon-folder-open-empty:before {
  content: '\e81d';
}
.nGY2Icon-menu:before {
  content: '\e81e';
}
.nGY2Icon-cog:before {
  content: '\e81f';
}
.nGY2Icon-cog-alt:before {
  content: '\e820';
}
.nGY2Icon-wrench:before {
  content: '\e821';
}
.nGY2Icon-lightbulb:before {
  content: '\e822';
}
.nGY2Icon-resize-full:before {
  content: '\e823';
}
.nGY2Icon-resize-small:before {
  content: '\e824';
}
.nGY2Icon-left-open:before {
  content: '\e827';
}
.nGY2Icon-right-open:before {
  content: '\e828';
}
.nGY2Icon-arrows-cw:before {
  content: '\e829';
}
.nGY2Icon-level-up:before {
  content: '\e82a';
}
.nGY2Icon-play:before {
  content: '\e82b';
}
.nGY2Icon-pause:before {
  content: '\e82c';
}
.nGY2Icon-ngy2_chevron-left:before {
  content: '\e82d';
}
.nGY2Icon-ellipsis-vert:before {
  content: '\e82e';
}
.nGY2Icon-toggle-off:before {
  content: '\e82f';
}
.nGY2Icon-toggle-on:before {
  content: '\e830';
}
.nGY2Icon-check:before {
  content: '\e831';
}
.nGY2Icon-check-empty:before {
  content: '\e832';
}
.nGY2Icon-rocket:before {
  content: '\e833';
}
.nGY2Icon-filter:before {
  content: '\e834';
}
.nGY2Icon-magic:before {
  content: '\e835';
}
.nGY2Icon-pinterest-squared:before {
  content: '\e836';
}
.nGY2Icon-gplus-squared:before {
  content: '\e837';
}
.nGY2Icon-facebook-squared:before {
  content: '\e838';
}
.nGY2Icon-basket:before {
  content: '\e839';
}
.nGY2Icon-ok-circled:before {
  content: '\e83a';
}
.nGY2Icon-user:before {
  content: '\e83b';
}
.nGY2Icon-ngy2_chevron_left3:before {
  content: '\e83c';
}
.nGY2Icon-ngy2_chevron_right3:before {
  content: '\e83d';
}
.nGY2Icon-zoom-out-1:before {
  content: '\e83e';
}
.nGY2Icon-ngy2_zoom_out2:before {
  content: '\e83f';
}
.nGY2Icon-ngy2_zoom_in2:before {
  content: '\e840';
}
.nGY2Icon-ngy2_share2:before {
  content: '\e841';
}
.nGY2Icon-ngy2_external2:before {
  content: '\e842';
}
.nGY2Icon-ngy2_close2:before {
  content: '\e843';
}
.nGY2Icon-ngy2_info2:before {
  content: '\e844';
}
.nGY2Icon-ngy2_chevron_up2:before {
  content: '\e845';
}
.nGY2Icon-ngy2_download2:before {
  content: '\e846';
}
.nGY2Icon-mail-alt:before {
  content: '\f0e0';
}
.nGY2Icon-circle-empty:before {
  content: '\f10c';
}
.nGY2Icon-tumblr-squared:before {
  content: '\f174';
}
.nGY2Icon-twitter-squared:before {
  content: '\f304';
}
.nGY2Icon-youtube-play:before {
  content: '\f16a';
}
.nGY2Icon-vkontakte:before {
  content: '\f189';
}
.nGY2Icon-cw:before {
  content: '\e809';
}
.nGY2Icon-ccw:before {
  content: '\e80c';
}
[class^='nGY2Icon-'] {
  margin-left: 0.2em;
  margin-right: 0.3em;
  font-family: ngy2_icon_font;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2Navigationbar {
  margin: 5px 0;
  padding: 5px 0;
  display: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2Navigationbar .nGY2NavigationbarItem {
  margin: 5px 2px;
  padding: 5px 8px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  visibility: 'hidden';
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2Gallery,
.nGY2 .nGY2GallerySub {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.nGY2Navigationbar .oneItem {
  margin: 0 5px;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
}
.nGY2 .nGY2Gallery {
  text-align: center;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GallerySub {
  perspective: 900px;
  text-align: left;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnail,
.nGY2 .nGY2GThumbnailStack {
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  border: 0 solid #000;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.nGY2 .nGY2GThumbnail {
  background-color: #000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailStack {
  background-color: #888;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailSub {
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  border: 0 solid #000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.nGY2 .nGY2GThumbnailSubSelected {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.nGY2 .nGY2GThumbnailImage {
  position: relative;
  overflow: hidden;
  background: #000;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailImg {
  background: url(data:image/gif;base64,R0lGODlhEAAQAIAAAP///////yH5BAEKAAEALAAAAAAQABAAAAIOjI+py+0Po5y02ouzPgUAOw==)
    center no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  vertical-align: bottom;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailAnnotationOLD_NO_MORE_USED {
  background: rgba(34, 34, 34, 0.75);
  opacity: 1;
  text-align: left;
  left: 0;
  right: 0;
  padding: 1px;
  position: absolute;
  min-height: 18px;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailCustomLayer,
.nGY2 .nGY2GThumbnailLabel {
  display: block;
  box-sizing: border-box;
  left: 0;
  position: absolute;
  right: 0;
}
.nGY2 .nGY2GThumbnailCustomLayer {
  top: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailLabel {
  background: rgba(34, 34, 34, 0.75);
  padding: 4px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailAlbumTitle,
.nGY2 .nGY2GThumbnailImageTitle {
  color: #fff;
  margin: 5px 1px 1px;
  padding: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0,
    #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailDescription {
  clear: both;
  margin: 1px 1px 3px;
  padding: 0;
  color: #aaa;
  white-space: nowrap;
  left: 0;
  right: 0;
  font-size: 0.8em;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0,
    #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailIcon,
.nGY2 .nGY2GThumbnailIconsFullThumbnail {
  color: #fff;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0,
    #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0;
}
.nGY2 .nGY2GThumbnailIcons {
  margin: 0;
  position: absolute;
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailIconsFullThumbnail {
  font-size: 1.8em;
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailIconText,
.nGY2 .nGY2GThumbnailIconTextBadge {
  position: relative;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
.nGY2 .nGY2GThumbnailIcon {
  display: inline-block;
  padding: 4px;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailIconTextBadge {
  background-color: #fff;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailIconText {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailAlbumUp i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.nGY2 .nGY2GThumbnailAlbumUp {
  color: #eee;
  font-size: 1.2em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: table;
  width: 100%;
  text-align: center;
  position: absolute;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2GalleryBottom {
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
.nGY2 .nGY2GalleryMoreButton,
.nGY2 .nGY2GalleryMoreButtonAnnotation,
.nGY2 .nGY2GalleryPagination,
.nGY2 .nGY2GalleryPaginationDot {
  margin: 10px auto 5px;
  text-align: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}
.nGY2 .nGY2GalleryMoreButton {
  padding: 4px;
  display: inline-block;
  cursor: pointer;
}
.nGY2 .nGY2GalleryMoreButtonAnnotation {
  border: 1px solid #fff;
  padding: 8px 20px;
  display: inline-block;
}
.nGY2 .nGY2GalleryPagination,
.nGY2 .nGY2GalleryPaginationDot {
  padding: 4px;
}
.nGY2 .nGY2PaginationNext,
.nGY2 .nGY2PaginationPrev,
.nGY2 .nGY2paginationItem,
.nGY2 .nGY2paginationItemCurrentPage {
  margin: 10px 4px;
  padding: 5px 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #111;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
}
.nGY2 .nGY2paginationItemCurrentPage {
  background: #333;
}
.nGY2 .nGY2paginationDot,
.nGY2 .nGY2paginationDotCurrentPage {
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  background: #444;
  border-radius: 50%;
  margin: 12px 5px;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  opacity: 0.5;
}
.nGY2 .nGY2paginationDotCurrentPage {
  background: #fff;
  opacity: 1;
}
.nGY2 .nGY2paginationRectangle,
.nGY2 .nGY2paginationRectangleCurrentPage {
  width: 30px;
  border: 1px solid #fff;
  height: 0;
  background: #444;
  margin: 5px 1px;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  opacity: 0.5;
}
.nGY2 .nGY2paginationRectangleCurrentPage {
  background: #fff;
  opacity: 1;
}
.nGY2Popup {
  position: fixed;
  color: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}
.nGY2PopupContent,
.nGY2PopupContentCenter,
.nGY2PopupContentLeft,
.nGY2PopupContentRight {
  position: fixed;
  text-align: left;
  top: 50%;
  left: 50%;
  width: 50%;
  padding: 15px 30px;
  height: auto;
  background: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.nGY2PopupContentCenter {
  text-align: center;
}
.nGY2PopupTitle {
  font-size: 1.5em;
  border-bottom: 4px solid #888;
  padding: 0 0 5px;
  margin-bottom: 10px;
}
.nGY2PopupCloseButton {
  font-size: 1.5em;
  cursor: pointer;
  text-align: right;
}
.nGY2PopupOneItem {
  padding: 10px;
  font-size: 2em;
  cursor: pointer;
  display: inline-block;
}
.nGY2PopupOneItemText {
  padding: 10px;
  font-size: 1.2em;
  cursor: pointer;
  display: block;
}
@media only screen and (max-device-width: 480px) {
  .nGY2PopupContent {
    width: 85% !important;
    padding: 5px 10px !important;
  }
}
.nGY2PortInfo {
  padding: 3px !important;
  font-size: 14px !important;
  color: #ff0075 !important;
  text-align: center !important;
  z-index: 0 !important;
  text-transform: lowercase !important;
  cursor: pointer !important;
}
.nGY2PortInfo a,
.nGY2PortInfo a:active,
.nGY2PortInfo a:hover,
.nGY2PortInfo > a:link,
.nGY2PortInfo > a:visited {
  color: #ff0075 !important;
  text-decoration: none !important;
}
.nGY2 .nGY2ViewerContainer {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.nGY2 .nGY2Viewer {
  display: block;
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: rgba(1, 1, 1, 0.85);
  text-align: center;
  clear: both;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.nGY2 .nGY2ViewerLogo {
  text-align: center;
  float: left;
  position: relative;
  top: 50px;
  left: 5px;
  padding: 2px;
  background: url(nanogallery2_logo.png) center no-repeat;
  display: block;
  height: 74px;
  width: 147px;
}
.nGY2 .nGY2ViewerContent,
.nGY2 .nGY2ViewerMediaPan {
  position: absolute;
  box-sizing: border-box;
  user-select: none;
}
.nGY2 .nGY2ViewerContent {
  min-width: 40px;
  min-height: 40px;
  clear: both;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2ViewerMediaPan {
  transform-origin: 50% 50% 0;
  visibility: visible;
  opacity: 1;
  display: inline-block;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  zoom: 1;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2ViewerMedia,
.nGY2 .nGY2ViewerMediaLoaderHidden {
  visibility: visible;
  position: absolute;
  top: 0;
  bottom: 0;
  zoom: 1;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin: auto;
}
.nGY2 .nGY2ViewerMedia {
  max-width: none;
  opacity: 1;
  will-change: transform;
  transform: translateZ(0);
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2ViewerMediaLoaderDisplayed {
  visibility: visible;
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  zoom: 1;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 50px;
  height: 50px;
}
.nGY2 .nGY2ViewerMediaLoaderDisplayed:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: '';
  display: block;
  position: absolute;
  border-width: 7px;
  border-style: solid;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  animation: nGY2-spinner-rotate-animation 1s linear 0s infinite;
  border-color: #444 #23cb99 #23cb99 #444;
}
.nGY2 .nGY2ViewerMediaLoaderDisplayed:after {
  content: '';
  display: block;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: nGY2-spinner-anti-rotate-animation 0.85s linear 0s infinite;
  border-color: #333 #23cb99 #23cb99 #333;
}
@keyframes nGY2-spinner-rotate-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes nGY2-spinner-anti-rotate-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.nGY2 .nGY2ViewerMediaLoaderHidden {
  opacity: 0;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  background-image: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.nGY2 .nGY2ViewerAreaNext,
.nGY2 .nGY2ViewerAreaPrevious {
  transform: scaleY(1.5);
  color: #fff;
  display: block;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0,
    #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0;
  font-size: 2em;
  top: 50%;
  margin-top: -25px;
  cursor: pointer;
  opacity: 1;
  position: absolute;
}
.nGY2 .nGY2ViewerContent .imgCurrent {
  cursor: pointer;
}
.nGY2 .nGY2ViewerAreaPrevious {
  padding: 10px 0 10px 1px;
  left: 0;
  text-align: left;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.nGY2 .nGY2ViewerAreaPrevious > i {
  margin-left: 0;
}
.nGY2 .nGY2ViewerAreaNext {
  padding: 10px 1px 10px 0;
  right: 0;
  text-align: right;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.nGY2 .nGY2ViewerAreaNext > i {
  margin-right: 0;
}
.nGY2 .toolbarContainer {
  left: 0;
  right: 0;
  text-align: center;
  display: inline-block;
  position: absolute;
  width: 100%;
}
.nGY2 .toolbar {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 2px 0;
  margin: 1px;
}
.nGY2 .toolbarBackground {
  background: rgba(4, 4, 4, 0.7);
}
.nGY2 .nGY2ViewerToolsTopLeft,
.nGY2 .nGY2ViewerToolsTopRight {
  color: #ddd;
  background: rgba(0, 0, 0, 0.2);
  top: 5px;
  position: absolute;
  cursor: pointer;
  opacity: 1;
}
.nGY2 .nGY2ViewerToolsTopLeft {
  left: 5px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.nGY2 .nGY2ViewerToolsTopRight {
  right: 5px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.nGY2 .toolbar .ngbt {
  font-size: 1.5em;
  display: table-cell;
  cursor: pointer;
  padding: 2px 5px;
  vertical-align: middle;
}
.nGY2 .toolbar .label,
.nGY2 .toolbar .label .title,
.nGY2 .toolbar .pageCounter {
  font-size: 1em;
  vertical-align: middle;
  overflow: hidden;
}
.nGY2 .ngy2viewerToolAction {
  opacity: 1;
}
.nGY2 .ngy2viewerToolAction:hover {
  opacity: 0.8;
}
.nGY2 .toolbar .pageCounter {
  display: table-cell;
  margin: auto;
}
.nGY2 .toolbar .label {
  padding: 3px 10px;
  border-left: 0 solid #000;
  display: table-cell;
  text-align: left;
  background-color: initial;
}
.nGY2 .toolbar .label .title {
  margin: auto;
}
.nGY2 .toolbar .label .description {
  font-size: 0.8em;
  display: table-row;
  vertical-align: middle;
  overflow: hidden;
  color: #aaa;
}
.nGY2ConsoleParent {
  visibility: hidden;
  height: 0;
  background: #111;
  color: #e00;
  padding: 0;
  margin: 2px;
}
.nGY2ConsoleParent p {
  color: #e00;
  padding: 1px;
  margin: 0;
}
.nGY2 .nanoGalleryLBar,
.nGY2 .nanoGalleryLBarOff {
  position: relative;
  width: 100%;
  margin: 0 0 1px;
  background-color: #556;
  height: 2px;
}
.nGY2.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.nGY2 .nanoGalleryLBarOff {
  visibility: hidden;
}
.nGY2 .nanoGalleryLBar {
  visibility: visible;
}
.nGY2 .nanoGalleryLBar > div {
  position: absolute;
  width: 50px;
  height: 2px;
  top: 0;
  opacity: 0.7;
}
.nGY2 .nanoGalleryLBar > div:nth-child(1) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #111;
}
.nGY2 .nanoGalleryLBar > div:nth-child(2) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #333;
}
.nGY2 .nanoGalleryLBar > div:nth-child(3) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #ccd;
}
.nGY2 .nanoGalleryLBar > div:nth-child(4) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #777;
}
.nGY2 .nanoGalleryLBar > div:nth-child(5) {
  -webkit-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #ddd;
}
@-webkit-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%;
  }
  100% {
    left: 90%;
  }
}
@-moz-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%;
  }
  100% {
    left: 90%;
  }
}
@-ms-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%;
  }
  100% {
    left: 90%;
  }
}
@-o-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%;
  }
  100% {
    left: 90%;
  }
}
@keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%;
  }
  100% {
    left: 90%;
  }
}
