.m04 {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding: 100px 15px;

  &__content {
    margin-bottom: 40px;
    p {
      font-size: 18px;
      line-height: em(24, 18);
      text-align: center;
    }
  }
}
