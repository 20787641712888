.m10 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 15px;

  &__form {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 20px;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include mobile {
      padding: 20px 15px;
    }
  }
  .wpcf7-submit {
    margin: 30px auto 0;
    display: block;
  }
}
