.m18 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 80px;
  padding-bottom: 100px;
  @include mobile {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  &__header {
    margin-top: 100px;
    margin-bottom: 80px;
    text-align: center;
    @include mobile {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
  &__images {
    width: 100%;
  }
  &__image {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
}
.nanogallery_gallerytheme_dark_m18__images .nGY2GThumbnail {
  border-color: #fff !important;
  background: #fff !important;
}
