.m08 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 15px;
  &_wrapper {
    @include mobile {
      width: 100%;
      padding: 0 15px;
    }
    &__inner {
      max-width: 1500px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 100px;
      @include mobile {
        margin-bottom: 50px;
      }
    }
  }
  &__slider {
    height: 500px;
    margin-bottom: 20px;
    @include mobile {
      height: 350px;
    }
    &__image {
      width: 100%;
      height: 500px;
      float: left;
      outline: 0;
      @include mobile {
        height: 350px;
      }
      img {
        @extend %imgCropped;
      }
    }
    &__thumbs {
      width: 100%;
      .slick-track {
        margin: 0 -8px;
      }
      &__image {
        float: left;
        height: 160px;
        padding: 0 8px;
        outline: 0;
        cursor: pointer;
        @include mobile {
          height: 130px;
        }
        img {
          @extend %imgCropped;
        }
      }
    }
  }

  &__description {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 30px;
    p {
      width: 50%;
      font-size: 32px;
      line-height: em(42, 32);
      @include mobile {
        width: 100%;
        font-size: 24px;
        line-height: em(30, 24);
      }
    }
  }
  &__plan {
    @include mobile {
      margin-top: 50px;
    }
  }

  &__plan3d {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
    margin-bottom: 30px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100vh;
    }
    .main__button {
      margin-top: 50px;
      a {
        background-image: none;
        padding-right: 20px;
      }
    }
  }

  &__services {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 1240px;
  }
  &__service {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    &__image {
      margin-bottom: 25px;
    }
    &__content {
      color: #000;
      h4 {
        font-family: 'Gilroy', sans-serif;
        font-size: 26px;
        line-height: em(34, 26);
        margin-bottom: 5px;
      }
      p {
        font-size: 16px;
        line-height: em(22, 16);
        text-align: left;
      }
    }
  }
}
