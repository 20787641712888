.home {
  .main_footer {
    &::after {
      display: none;
    }
  }
  .footer_wrapper {
    background-color: #fafbfb;
  }
  .footer_title {
    h3 {
      color: #000000;
    }
  }
  .footer_content {
    p {
      color: #5f5655;
    }
    a {
      color: #5f5655;
    }
    .input_email {
      border: 1px solid #000000;
      background-color: #fafbfb;
      color: #000;
      @include placeholder {
        color: #5f5655;
      }
    }
    .submit_button {
      background: url('./images/icon-submit.svg') no-repeat center #000;
    }
  }
  .footer_bottom {
    border-top: 1px solid #5f5655;
  }
  .footer_copyright {
    p {
      color: #5f5655;
    }
  }
}
.main_footer {
  width: 100%;
  background-color: #0f0d0d;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: -1;
    background-image: url('./images/footer-bg.svg');
    background-repeat: no-repeat;
    background-position: left top;
  }
}
.footer_wrapper {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 116px;
  @include mobile {
    padding: 0px 15px;
  }
}

.footer_title {
  display: flex;
  h3 {
    font-size: 27px;
    margin-bottom: 44px;
    font-family: 'Gilroy', sans-serif;
    margin-top: 120px;
    color: #fafbfb;
    @include mobile {
      font-size: 24px;
      margin-bottom: 20px;
      margin-top: 50px;
    }
  }
}
.footer_content {
  margin-bottom: 120px;
  @include mobile {
    margin-bottom: 0;
  }
  p {
    font-weight: 100;
    font-size: 15px;
    color: #fafbfb;
    line-height: em(18, 15);
  }
  li {
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: #fafbfb;
  }
  form {
    display: flex;
    margin-bottom: 30px;
    position: relative;
  }
  .input_email {
    //width: calc(100% - 70px);
    width: 100%;
    border: 1px solid #fafbfb;
    padding: 16px 15px 14px;
    margin: 0;
    background-color: #0f0d0d;
    outline: 0;
    font-size: 15px;
    color: #fafbfb;
    @include placeholder {
      font-size: 15px;
      color: #fafbfb;
    }
  }
  .submit_button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border: 0;
    outline: 0;
    background: url('./images/icon-submit-black.svg') no-repeat center #fff;
    cursor: pointer;
  }
}
.footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid #fafbfb;
}
.footer_copyright {
  display: flex;
  img {
    width: 12px;
    margin-right: 5px;
  }
  p {
    font-weight: 100;
    font-size: 15px;
    line-height: em(18, 15);
    color: #fafbfb;
    margin-left: 2px;
  }
}
.footer_socials {
  display: flex;
  justify-content: flex-end;
  a {
    padding-left: 15px;
  }
  img {
    height: 16px;
  }
}
