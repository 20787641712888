body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0s; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0s; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0s; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0s; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0s; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0s; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0s; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0s; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0s; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0s; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0s; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0s; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0s; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0s; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0s; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0s; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0s; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0s; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0s; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0s; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0s; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0s; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0s; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0s; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0s; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0s; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0s; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0s; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0s; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0s; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0s; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0s; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0s; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0s; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0s; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0s; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0s; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0s; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0s; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0s; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0s; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0s; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0s; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0s; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0s; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0s; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0s; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0s; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0s; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0s; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0s; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0s; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0s; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0s; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0s; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0s; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0s; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0s; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0s; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0s; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

[data-aos] {
  pointer-events: none; }
  [data-aos].aos-animate {
    pointer-events: auto; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */ }
    html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].aos-animate {
        opacity: 1;
        transform: none; }
    html:not(.no-js) [data-aos='fade-up'] {
      transform: translate3d(0, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down'] {
      transform: translate3d(0, -100px, 0); }
    html:not(.no-js) [data-aos='fade-right'] {
      transform: translate3d(-100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-left'] {
      transform: translate3d(100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-up-right'] {
      transform: translate3d(-100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-up-left'] {
      transform: translate3d(100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down-right'] {
      transform: translate3d(-100px, -100px, 0); }
    html:not(.no-js) [data-aos='fade-down-left'] {
      transform: translate3d(100px, -100px, 0); }
    html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1); }
    html:not(.no-js) [data-aos='zoom-in'] {
      transform: scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-up'] {
      transform: translate3d(0, 100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-down'] {
      transform: translate3d(0, -100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-right'] {
      transform: translate3d(-100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-left'] {
      transform: translate3d(100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-out'] {
      transform: scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-up'] {
      transform: translate3d(0, 100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-down'] {
      transform: translate3d(0, -100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-right'] {
      transform: translate3d(-100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-left'] {
      transform: translate3d(100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos^='slide'][data-aos^='slide'] {
      transition-property: transform;
      visibility: hidden; }
      html:not(.no-js) [data-aos^='slide'][data-aos^='slide'].aos-animate {
        visibility: visible;
        transform: translate3d(0, 0, 0); }
    html:not(.no-js) [data-aos='slide-up'] {
      transform: translate3d(0, 100%, 0); }
    html:not(.no-js) [data-aos='slide-down'] {
      transform: translate3d(0, -100%, 0); }
    html:not(.no-js) [data-aos='slide-right'] {
      transform: translate3d(-100%, 0, 0); }
    html:not(.no-js) [data-aos='slide-left'] {
      transform: translate3d(100%, 0, 0); }
    html:not(.no-js) [data-aos^='flip'][data-aos^='flip'] {
      backface-visibility: hidden;
      transition-property: transform; }
    html:not(.no-js) [data-aos='flip-left'] {
      transform: perspective(2500px) rotateY(-100deg); }
      html:not(.no-js) [data-aos='flip-left'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-right'] {
      transform: perspective(2500px) rotateY(100deg); }
      html:not(.no-js) [data-aos='flip-right'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-up'] {
      transform: perspective(2500px) rotateX(-100deg); }
      html:not(.no-js) [data-aos='flip-up'].aos-animate {
        transform: perspective(2500px) rotateX(0); }
    html:not(.no-js) [data-aos='flip-down'] {
      transform: perspective(2500px) rotateX(100deg); }
      html:not(.no-js) [data-aos='flip-down'].aos-animate {
        transform: perspective(2500px) rotateX(0); } }

.nGY2 {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box; }

.nGY2 .ngy2_container {
  width: 100%;
  min-width: 100px;
  font-size: 1em;
  line-height: normal;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  visibility: visible;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none; }

@font-face {
  font-family: ngy2_icon_font;
  src: url(font/ngy2_icon_font.woff2?64889571) format("woff2"), url(font/ngy2_icon_font.woff?64889571) format("woff");
  font-weight: 400;
  font-style: normal; }

.nGY2Icon-star:before {
  content: '\e801'; }

.nGY2Icon-search:before {
  content: '\e800'; }

.nGY2Icon-star-empty:before {
  content: '\e802'; }

.nGY2Icon-video:before {
  content: '\e803'; }

.nGY2Icon-videocam:before {
  content: '\e804'; }

.nGY2Icon-picture:before {
  content: '\e805'; }

.nGY2Icon-camera:before {
  content: '\e806'; }

.nGY2Icon-camera-alt:before {
  content: '\e807'; }

.nGY2Icon-ok:before {
  content: '\e808'; }

.nGY2Icon-help:before {
  content: '\e80a'; }

.nGY2Icon-help-circled:before {
  content: '\e80b'; }

.nGY2Icon-home:before {
  content: '\e80e'; }

.nGY2Icon-link:before {
  content: '\e80f'; }

.nGY2Icon-link-ext:before {
  content: '\e810'; }

.nGY2Icon-heart:before {
  content: '\e811'; }

.nGY2Icon-ngy2_chevron-right:before {
  content: '\e812'; }

.nGY2Icon-upload:before {
  content: '\e814'; }

.nGY2Icon-reply-all:before {
  content: '\e815'; }

.nGY2Icon-export:before {
  content: '\e816'; }

.nGY2Icon-chat:before {
  content: '\e818'; }

.nGY2Icon-attention:before {
  content: '\e819'; }

.nGY2Icon-location:before {
  content: '\e81a'; }

.nGY2Icon-trash:before {
  content: '\e81b'; }

.nGY2Icon-folder-empty:before {
  content: '\e81c'; }

.nGY2Icon-folder-open-empty:before {
  content: '\e81d'; }

.nGY2Icon-menu:before {
  content: '\e81e'; }

.nGY2Icon-cog:before {
  content: '\e81f'; }

.nGY2Icon-cog-alt:before {
  content: '\e820'; }

.nGY2Icon-wrench:before {
  content: '\e821'; }

.nGY2Icon-lightbulb:before {
  content: '\e822'; }

.nGY2Icon-resize-full:before {
  content: '\e823'; }

.nGY2Icon-resize-small:before {
  content: '\e824'; }

.nGY2Icon-left-open:before {
  content: '\e827'; }

.nGY2Icon-right-open:before {
  content: '\e828'; }

.nGY2Icon-arrows-cw:before {
  content: '\e829'; }

.nGY2Icon-level-up:before {
  content: '\e82a'; }

.nGY2Icon-play:before {
  content: '\e82b'; }

.nGY2Icon-pause:before {
  content: '\e82c'; }

.nGY2Icon-ngy2_chevron-left:before {
  content: '\e82d'; }

.nGY2Icon-ellipsis-vert:before {
  content: '\e82e'; }

.nGY2Icon-toggle-off:before {
  content: '\e82f'; }

.nGY2Icon-toggle-on:before {
  content: '\e830'; }

.nGY2Icon-check:before {
  content: '\e831'; }

.nGY2Icon-check-empty:before {
  content: '\e832'; }

.nGY2Icon-rocket:before {
  content: '\e833'; }

.nGY2Icon-filter:before {
  content: '\e834'; }

.nGY2Icon-magic:before {
  content: '\e835'; }

.nGY2Icon-pinterest-squared:before {
  content: '\e836'; }

.nGY2Icon-gplus-squared:before {
  content: '\e837'; }

.nGY2Icon-facebook-squared:before {
  content: '\e838'; }

.nGY2Icon-basket:before {
  content: '\e839'; }

.nGY2Icon-ok-circled:before {
  content: '\e83a'; }

.nGY2Icon-user:before {
  content: '\e83b'; }

.nGY2Icon-ngy2_chevron_left3:before {
  content: '\e83c'; }

.nGY2Icon-ngy2_chevron_right3:before {
  content: '\e83d'; }

.nGY2Icon-zoom-out-1:before {
  content: '\e83e'; }

.nGY2Icon-ngy2_zoom_out2:before {
  content: '\e83f'; }

.nGY2Icon-ngy2_zoom_in2:before {
  content: '\e840'; }

.nGY2Icon-ngy2_share2:before {
  content: '\e841'; }

.nGY2Icon-ngy2_external2:before {
  content: '\e842'; }

.nGY2Icon-ngy2_close2:before {
  content: '\e843'; }

.nGY2Icon-ngy2_info2:before {
  content: '\e844'; }

.nGY2Icon-ngy2_chevron_up2:before {
  content: '\e845'; }

.nGY2Icon-ngy2_download2:before {
  content: '\e846'; }

.nGY2Icon-mail-alt:before {
  content: '\f0e0'; }

.nGY2Icon-circle-empty:before {
  content: '\f10c'; }

.nGY2Icon-tumblr-squared:before {
  content: '\f174'; }

.nGY2Icon-twitter-squared:before {
  content: '\f304'; }

.nGY2Icon-youtube-play:before {
  content: '\f16a'; }

.nGY2Icon-vkontakte:before {
  content: '\f189'; }

.nGY2Icon-cw:before {
  content: '\e809'; }

.nGY2Icon-ccw:before {
  content: '\e80c'; }

[class^='nGY2Icon-'] {
  margin-left: 0.2em;
  margin-right: 0.3em;
  font-family: ngy2_icon_font;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2Navigationbar {
  margin: 5px 0;
  padding: 5px 0;
  display: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2Navigationbar .nGY2NavigationbarItem {
  margin: 5px 2px;
  padding: 5px 8px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  visibility: 'hidden';
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2Gallery,
.nGY2 .nGY2GallerySub {
  position: relative;
  overflow: hidden;
  box-sizing: border-box; }

.nGY2Navigationbar .oneItem {
  margin: 0 5px;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block; }

.nGY2 .nGY2Gallery {
  text-align: center;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GallerySub {
  perspective: 900px;
  text-align: left;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnail,
.nGY2 .nGY2GThumbnailStack {
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  border: 0 solid #000;
  padding: 0;
  overflow: hidden;
  cursor: pointer; }

.nGY2 .nGY2GThumbnail {
  background-color: #000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailStack {
  background-color: #888;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailSub {
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  border: 0 solid #000;
  margin: 0;
  padding: 0;
  overflow: hidden; }

.nGY2 .nGY2GThumbnailSubSelected {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9); }

.nGY2 .nGY2GThumbnailImage {
  position: relative;
  overflow: hidden;
  background: #000;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailImg {
  background: url(data:image/gif;base64,R0lGODlhEAAQAIAAAP///////yH5BAEKAAEALAAAAAAQABAAAAIOjI+py+0Po5y02ouzPgUAOw==) center no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  vertical-align: bottom;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailAnnotationOLD_NO_MORE_USED {
  background: rgba(34, 34, 34, 0.75);
  opacity: 1;
  text-align: left;
  left: 0;
  right: 0;
  padding: 1px;
  position: absolute;
  min-height: 18px;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailCustomLayer,
.nGY2 .nGY2GThumbnailLabel {
  display: block;
  box-sizing: border-box;
  left: 0;
  position: absolute;
  right: 0; }

.nGY2 .nGY2GThumbnailCustomLayer {
  top: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailLabel {
  background: rgba(34, 34, 34, 0.75);
  padding: 4px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailAlbumTitle,
.nGY2 .nGY2GThumbnailImageTitle {
  color: #fff;
  margin: 5px 1px 1px;
  padding: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0, #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailDescription {
  clear: both;
  margin: 1px 1px 3px;
  padding: 0;
  color: #aaa;
  white-space: nowrap;
  left: 0;
  right: 0;
  font-size: 0.8em;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0, #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailIcon,
.nGY2 .nGY2GThumbnailIconsFullThumbnail {
  color: #fff;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0, #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0; }

.nGY2 .nGY2GThumbnailIcons {
  margin: 0;
  position: absolute;
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailIconsFullThumbnail {
  font-size: 1.8em;
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailIconText,
.nGY2 .nGY2GThumbnailIconTextBadge {
  position: relative;
  display: inline-block;
  margin: 0;
  vertical-align: middle; }

.nGY2 .nGY2GThumbnailIcon {
  display: inline-block;
  padding: 4px;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailIconTextBadge {
  background-color: #fff;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailIconText {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GThumbnailAlbumUp i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%); }

.nGY2 .nGY2GThumbnailAlbumUp {
  color: #eee;
  font-size: 1.2em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: table;
  width: 100%;
  text-align: center;
  position: absolute;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2GalleryBottom {
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap; }

.nGY2 .nGY2GalleryMoreButton,
.nGY2 .nGY2GalleryMoreButtonAnnotation,
.nGY2 .nGY2GalleryPagination,
.nGY2 .nGY2GalleryPaginationDot {
  margin: 10px auto 5px;
  text-align: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden; }

.nGY2 .nGY2GalleryMoreButton {
  padding: 4px;
  display: inline-block;
  cursor: pointer; }

.nGY2 .nGY2GalleryMoreButtonAnnotation {
  border: 1px solid #fff;
  padding: 8px 20px;
  display: inline-block; }

.nGY2 .nGY2GalleryPagination,
.nGY2 .nGY2GalleryPaginationDot {
  padding: 4px; }

.nGY2 .nGY2PaginationNext,
.nGY2 .nGY2PaginationPrev,
.nGY2 .nGY2paginationItem,
.nGY2 .nGY2paginationItemCurrentPage {
  margin: 10px 4px;
  padding: 5px 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #111;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block; }

.nGY2 .nGY2paginationItemCurrentPage {
  background: #333; }

.nGY2 .nGY2paginationDot,
.nGY2 .nGY2paginationDotCurrentPage {
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  background: #444;
  border-radius: 50%;
  margin: 12px 5px;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  opacity: 0.5; }

.nGY2 .nGY2paginationDotCurrentPage {
  background: #fff;
  opacity: 1; }

.nGY2 .nGY2paginationRectangle,
.nGY2 .nGY2paginationRectangleCurrentPage {
  width: 30px;
  border: 1px solid #fff;
  height: 0;
  background: #444;
  margin: 5px 1px;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  opacity: 0.5; }

.nGY2 .nGY2paginationRectangleCurrentPage {
  background: #fff;
  opacity: 1; }

.nGY2Popup {
  position: fixed;
  color: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); }

.nGY2PopupContent,
.nGY2PopupContentCenter,
.nGY2PopupContentLeft,
.nGY2PopupContentRight {
  position: fixed;
  text-align: left;
  top: 50%;
  left: 50%;
  width: 50%;
  padding: 15px 30px;
  height: auto;
  background: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }

.nGY2PopupContentCenter {
  text-align: center; }

.nGY2PopupTitle {
  font-size: 1.5em;
  border-bottom: 4px solid #888;
  padding: 0 0 5px;
  margin-bottom: 10px; }

.nGY2PopupCloseButton {
  font-size: 1.5em;
  cursor: pointer;
  text-align: right; }

.nGY2PopupOneItem {
  padding: 10px;
  font-size: 2em;
  cursor: pointer;
  display: inline-block; }

.nGY2PopupOneItemText {
  padding: 10px;
  font-size: 1.2em;
  cursor: pointer;
  display: block; }

@media only screen and (max-device-width: 480px) {
  .nGY2PopupContent {
    width: 85% !important;
    padding: 5px 10px !important; } }

.nGY2PortInfo {
  padding: 3px !important;
  font-size: 14px !important;
  color: #ff0075 !important;
  text-align: center !important;
  z-index: 0 !important;
  text-transform: lowercase !important;
  cursor: pointer !important; }

.nGY2PortInfo a,
.nGY2PortInfo a:active,
.nGY2PortInfo a:hover,
.nGY2PortInfo > a:link,
.nGY2PortInfo > a:visited {
  color: #ff0075 !important;
  text-decoration: none !important; }

.nGY2 .nGY2ViewerContainer {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.nGY2 .nGY2Viewer {
  display: block;
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: rgba(1, 1, 1, 0.85);
  text-align: center;
  clear: both;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.nGY2 .nGY2ViewerLogo {
  text-align: center;
  float: left;
  position: relative;
  top: 50px;
  left: 5px;
  padding: 2px;
  background: url(nanogallery2_logo.png) center no-repeat;
  display: block;
  height: 74px;
  width: 147px; }

.nGY2 .nGY2ViewerContent,
.nGY2 .nGY2ViewerMediaPan {
  position: absolute;
  box-sizing: border-box;
  user-select: none; }

.nGY2 .nGY2ViewerContent {
  min-width: 40px;
  min-height: 40px;
  clear: both;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2ViewerMediaPan {
  transform-origin: 50% 50% 0;
  visibility: visible;
  opacity: 1;
  display: inline-block;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  zoom: 1;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2ViewerMedia,
.nGY2 .nGY2ViewerMediaLoaderHidden {
  visibility: visible;
  position: absolute;
  top: 0;
  bottom: 0;
  zoom: 1;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin: auto; }

.nGY2 .nGY2ViewerMedia {
  max-width: none;
  opacity: 1;
  will-change: transform;
  transform: translateZ(0);
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2ViewerMediaLoaderDisplayed {
  visibility: visible;
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  zoom: 1;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 50px;
  height: 50px; }

.nGY2 .nGY2ViewerMediaLoaderDisplayed:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: '';
  display: block;
  position: absolute;
  border-width: 7px;
  border-style: solid;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  animation: nGY2-spinner-rotate-animation 1s linear 0s infinite;
  border-color: #444 #23cb99 #23cb99 #444; }

.nGY2 .nGY2ViewerMediaLoaderDisplayed:after {
  content: '';
  display: block;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: nGY2-spinner-anti-rotate-animation 0.85s linear 0s infinite;
  border-color: #333 #23cb99 #23cb99 #333; }

@keyframes nGY2-spinner-rotate-animation {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes nGY2-spinner-anti-rotate-animation {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-360deg); } }

.nGY2 .nGY2ViewerMediaLoaderHidden {
  opacity: 0;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  background-image: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box; }

.nGY2 .nGY2ViewerAreaNext,
.nGY2 .nGY2ViewerAreaPrevious {
  transform: scaleY(1.5);
  color: #fff;
  display: block;
  text-shadow: #000 1px 0 0, #000 1px 1px 0, #000 1px -1px 0, #000 -1px 1px 0, #000 -1px 0 0, #000 -1px -1px 0, #000 0 1px 0, #000 0 -1px 0;
  font-size: 2em;
  top: 50%;
  margin-top: -25px;
  cursor: pointer;
  opacity: 1;
  position: absolute; }

.nGY2 .nGY2ViewerContent .imgCurrent {
  cursor: pointer; }

.nGY2 .nGY2ViewerAreaPrevious {
  padding: 10px 0 10px 1px;
  left: 0;
  text-align: left;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease; }

.nGY2 .nGY2ViewerAreaPrevious > i {
  margin-left: 0; }

.nGY2 .nGY2ViewerAreaNext {
  padding: 10px 1px 10px 0;
  right: 0;
  text-align: right;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease; }

.nGY2 .nGY2ViewerAreaNext > i {
  margin-right: 0; }

.nGY2 .toolbarContainer {
  left: 0;
  right: 0;
  text-align: center;
  display: inline-block;
  position: absolute;
  width: 100%; }

.nGY2 .toolbar {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 2px 0;
  margin: 1px; }

.nGY2 .toolbarBackground {
  background: rgba(4, 4, 4, 0.7); }

.nGY2 .nGY2ViewerToolsTopLeft,
.nGY2 .nGY2ViewerToolsTopRight {
  color: #ddd;
  background: rgba(0, 0, 0, 0.2);
  top: 5px;
  position: absolute;
  cursor: pointer;
  opacity: 1; }

.nGY2 .nGY2ViewerToolsTopLeft {
  left: 5px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease; }

.nGY2 .nGY2ViewerToolsTopRight {
  right: 5px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease; }

.nGY2 .toolbar .ngbt {
  font-size: 1.5em;
  display: table-cell;
  cursor: pointer;
  padding: 2px 5px;
  vertical-align: middle; }

.nGY2 .toolbar .label,
.nGY2 .toolbar .label .title,
.nGY2 .toolbar .pageCounter {
  font-size: 1em;
  vertical-align: middle;
  overflow: hidden; }

.nGY2 .ngy2viewerToolAction {
  opacity: 1; }

.nGY2 .ngy2viewerToolAction:hover {
  opacity: 0.8; }

.nGY2 .toolbar .pageCounter {
  display: table-cell;
  margin: auto; }

.nGY2 .toolbar .label {
  padding: 3px 10px;
  border-left: 0 solid #000;
  display: table-cell;
  text-align: left;
  background-color: initial; }

.nGY2 .toolbar .label .title {
  margin: auto; }

.nGY2 .toolbar .label .description {
  font-size: 0.8em;
  display: table-row;
  vertical-align: middle;
  overflow: hidden;
  color: #aaa; }

.nGY2ConsoleParent {
  visibility: hidden;
  height: 0;
  background: #111;
  color: #e00;
  padding: 0;
  margin: 2px; }

.nGY2ConsoleParent p {
  color: #e00;
  padding: 1px;
  margin: 0; }

.nGY2 .nanoGalleryLBar,
.nGY2 .nanoGalleryLBarOff {
  position: relative;
  width: 100%;
  margin: 0 0 1px;
  background-color: #556;
  height: 2px; }

.nGY2.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none; }

.nGY2 .nanoGalleryLBarOff {
  visibility: hidden; }

.nGY2 .nanoGalleryLBar {
  visibility: visible; }

.nGY2 .nanoGalleryLBar > div {
  position: absolute;
  width: 50px;
  height: 2px;
  top: 0;
  opacity: 0.7; }

.nGY2 .nanoGalleryLBar > div:nth-child(1) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.2s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #111; }

.nGY2 .nanoGalleryLBar > div:nth-child(2) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.4s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #333; }

.nGY2 .nanoGalleryLBar > div:nth-child(3) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.6s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #ccd; }

.nGY2 .nanoGalleryLBar > div:nth-child(4) {
  -webkit-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -0.8s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #777; }

.nGY2 .nanoGalleryLBar > div:nth-child(5) {
  -webkit-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -moz-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -ms-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  -o-animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  animation: nanoGalleryLBarAnim 2s -1s infinite cubic-bezier(0, 0.6, 0.9, 0);
  background: #ddd; }

@-webkit-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%; }
  100% {
    left: 90%; } }

@-moz-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%; }
  100% {
    left: 90%; } }

@-ms-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%; }
  100% {
    left: 90%; } }

@-o-keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%; }
  100% {
    left: 90%; } }

@keyframes nanoGalleryLBarAnim {
  0% {
    left: 10%; }
  100% {
    left: 90%; } }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

header,
main,
footer {
  float: left;
  width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }
  *:after, *:before {
    box-sizing: border-box; }

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  max-width: 100%;
  height: auto; }

i,
em {
  font-style: italic; }

b,
strong {
  font-weight: bold; }

body {
  overflow-x: hidden;
  position: relative; }

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-ExtraBold.woff2") format("woff2"), url("./fonts/Gilroy-ExtraBold.woff") format("woff"), url("./fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica-Bold.woff2") format("woff2"), url("./fonts/Helvetica-Bold.woff") format("woff"), url("./fonts/Helvetica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Light.woff2") format("woff2"), url("./fonts/Gilroy-Light.woff") format("woff"), url("./fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Regular.woff2") format("woff2"), url("./fonts/Gilroy-Regular.woff") format("woff"), url("./fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("./fonts/HelveticaNeue-UltraLight.woff2") format("woff2"), url("./fonts/HelveticaNeue-UltraLight.woff") format("woff"), url("./fonts/HelveticaNeue-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/HelveticaNeueRegular.woff2") format("woff2"), url("./fonts/HelveticaNeueRegular.woff") format("woff"), url("./fonts/HelveticaNeueRegular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Bold.woff2") format("woff2"), url("./fonts/Gilroy-Bold.woff") format("woff"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Walbaum";
  src: url("./fonts/Walbaum-Bold.woff2") format("woff2"), url("./fonts/Walbaum-Bold.woff") format("woff"), url("./fonts/Walbaum-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1; }
  .container:before,
  .row:before, .container:after,
  .row:after {
    content: " ";
    display: table; }
  .container:after,
  .row:after {
    clear: both; }

.headerWrap,
.mainWrap,
.footerWrap {
  float: left;
  width: 100%;
  clear: both; }

.m01 .m01-slider img, .m03__box__image img, .m06__item__image img, .m08__slider__image img, .m08__slider__thumbs__image img, .m09__item__image img, .m15__slider__image img, .m15__slider__thumbs__image img, .m16__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

/*=====  End of EXTEND SELECTORS  ======*/
/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
.column-1 {
  width: 8.33333%; }

.column-2 {
  width: 16.66667%; }

.column-3 {
  width: 25%; }

.column-4 {
  width: 33.33333%; }

.column-5 {
  width: 41.66667%; }

.column-6 {
  width: 50%; }

.column-7 {
  width: 58.33333%; }

.column-8 {
  width: 66.66667%; }

.column-9 {
  width: 75%; }

.column-10 {
  width: 83.33333%; }

.column-11 {
  width: 91.66667%; }

.column-12 {
  width: 100%; }

.wow {
  visibility: hidden; }

[class*="column-"] {
  float: left;
  min-height: 1px; }

.has_gutter [class*="column-"] {
  padding: 0 15px; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 15px; }
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .container {
      padding: 0 32px;
      max-width: 762px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .container {
      max-width: 1000px; } }
  @media only screen and (min-width: 1200px) {
    .container {
      max-width: 1224px; } }

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important; }
  .show-tab {
    display: none !important; }
  .show-tab-big {
    display: none !important; } }

.hidden-mob {
  display: block; }

.hidden {
  display: none !important; }

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%; }
  .column-mob-2 {
    width: 16.66667%; }
  .column-mob-3 {
    width: 25%; }
  .column-mob-4 {
    width: 33.33333%; }
  .column-mob-5 {
    width: 41.66667%; }
  .column-mob-6 {
    width: 50%; }
  .column-mob-7 {
    width: 58.33333%; }
  .column-mob-8 {
    width: 66.66667%; }
  .column-mob-9 {
    width: 75%; }
  .column-mob-10 {
    width: 83.33333%; }
  .column-mob-11 {
    width: 91.66667%; }
  .column-mob-12 {
    width: 100%; }
  .show-mob {
    display: block !important; }
  .hidden-mob {
    display: none !important; } }

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%; }
  .column-tab-2 {
    width: 16.66667%; }
  .column-tab-3 {
    width: 25%; }
  .column-tab-4 {
    width: 33.33333%; }
  .column-tab-5 {
    width: 41.66667%; }
  .column-tab-6 {
    width: 50%; }
  .column-tab-7 {
    width: 58.33333%; }
  .column-tab-8 {
    width: 66.66667%; }
  .column-tab-9 {
    width: 75%; }
  .column-tab-10 {
    width: 83.33333%; }
  .column-tab-11 {
    width: 91.66667%; }
  .column-tab-12 {
    width: 100%; }
  .show-tab {
    display: block !important; }
  .hidden-tab {
    display: none !important; } }

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%; }
  .column-tab-big-2 {
    width: 16.66667%; }
  .column-tab-big-3 {
    width: 25%; }
  .column-tab-big-4 {
    width: 33.33333%; }
  .column-tab-big-5 {
    width: 41.66667%; }
  .column-tab-big-6 {
    width: 50%; }
  .column-tab-big-7 {
    width: 58.33333%; }
  .column-tab-big-8 {
    width: 66.66667%; }
  .column-tab-big-9 {
    width: 75%; }
  .column-tab-big-10 {
    width: 83.33333%; }
  .column-tab-big-11 {
    width: 91.66667%; }
  .column-tab-big-12 {
    width: 100%; }
  .show-tablet-big {
    display: block !important; }
  .hidden-tablet-big {
    display: none !important; } }

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none; }

.no_padding {
  padding: 0 !important; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

body {
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  background-color: #fafbfb;
  color: black;
  position: relative;
  overflow-x: hidden; }

input,
textarea,
select,
option {
  font-family: 'Gilroy', sans-serif;
  opacity: 1; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  option::-webkit-input-placeholder {
    font-family: 'Gilroy', sans-serif;
    opacity: 1;
    font-size: 14px;
    color: black; }
  input:-moz-placeholder,
  textarea:-moz-placeholder,
  select:-moz-placeholder,
  option:-moz-placeholder {
    font-family: 'Gilroy', sans-serif;
    opacity: 1;
    font-size: 14px;
    color: black; }
  input::-moz-placeholder,
  textarea::-moz-placeholder,
  select::-moz-placeholder,
  option::-moz-placeholder {
    font-family: 'Gilroy', sans-serif;
    opacity: 1;
    font-size: 14px;
    color: black; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  select:-ms-input-placeholder,
  option:-ms-input-placeholder {
    font-family: 'Gilroy', sans-serif;
    opacity: 1;
    font-size: 14px;
    color: black; }

.main_title {
  text-align: center;
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .main_title {
      margin-bottom: 25px; } }
  .main_title h3 {
    font-family: 'Gilroy', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1.14286em;
    position: relative;
    padding-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .main_title h3 {
        font-size: 32px;
        line-height: 1.25em;
        padding-bottom: 15px; } }
  .main_title h3:after {
    content: '';
    width: 130px;
    height: 2px;
    background: #ff0601;
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%); }
    @media only screen and (max-width: 767px) {
      .main_title h3:after {
        width: 90px; } }
  .main_title span {
    display: inline-block;
    font-size: 22px;
    line-height: 1.27273em;
    text-transform: uppercase;
    color: #ff0601;
    margin-bottom: 10px; }

.main__button {
  text-align: center; }
  .main__button a {
    display: inline-block;
    padding: 14px 40px 10px 20px;
    font-family: 'Gilroy';
    font-size: 18px;
    line-height: 1.33333em;
    font-weight: bold;
    color: #fafbfb;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #ff0601;
    background-image: url("./images/icon-swipe.svg");
    background-repeat: no-repeat;
    background-position: center right 24px;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .main__button a:hover {
      background-color: #e70500; }

.formInput {
  width: 100%;
  height: 50px;
  border: 1px solid #000000;
  outline: 0;
  appearance: none;
  padding: 15px;
  font-size: 18px;
  line-height: 1.33333em;
  color: #000;
  background-color: #fafbfb; }
  .formInput::-webkit-input-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }
  .formInput:-moz-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }
  .formInput::-moz-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }
  .formInput:-ms-input-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }

.formTextarea {
  width: 100%;
  border: 1px solid #000000;
  outline: 0;
  appearance: none;
  padding: 15px;
  font-size: 18px;
  line-height: 1.33333em;
  color: #000;
  background-color: #fafbfb; }
  .formTextarea::-webkit-input-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }
  .formTextarea:-moz-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }
  .formTextarea::-moz-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }
  .formTextarea:-ms-input-placeholder {
    font-size: 18px;
    line-height: 1.33333em;
    color: #5f5655; }

.wpcf7-form-control-wrap {
  width: 100%;
  display: block;
  margin-bottom: 20px; }

.wpcf7-not-valid-tip {
  margin-top: 5px; }

.wpcf7-submit {
  appearance: none;
  display: inline-block;
  padding: 14px 20px 10px;
  min-width: 200px;
  font-family: 'Gilroy';
  font-size: 18px;
  line-height: 1.33333em;
  font-weight: bold;
  color: #fafbfb;
  text-align: center;
  background-color: #ff0601;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
  .wpcf7-submit:hover {
    background-color: #e70500; }

.wpcf7 form .wpcf7-response-output {
  margin: 0;
  margin-top: 20px; }

.headerWrap {
  float: none;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #fafbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px; }
  @media only screen and (max-width: 767px) {
    .headerWrap {
      padding: 10px 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .headerWrap {
      padding: 10px 15px; } }

.main_content {
  margin-top: 86px; }
  @media only screen and (max-width: 767px) {
    .main_content {
      margin-top: 76px; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .main_content {
      margin-top: 76px; } }

.main_header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  z-index: 99; }
  .main_header__logo {
    width: 100px; }
  .main_header__right {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .main_header__right {
        align-items: center; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .main_header__right {
        align-items: center; } }
  .main_header__right img {
    width: 20px; }

.nav_wrapper {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .nav_wrapper.is--toggled {
      display: block; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .nav_wrapper.is--toggled {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .nav_wrapper {
      display: none;
      position: fixed;
      top: 76px;
      left: 0;
      width: 100%;
      background: #fff;
      z-index: 4;
      padding: 70px 15px;
      border-top: 1px solid #000; } }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .nav_wrapper {
      display: none;
      position: fixed;
      top: 76px;
      left: 0;
      width: 100%;
      background: #fff;
      z-index: 4;
      padding: 70px 15px;
      border-top: 1px solid #000; } }
  .nav_wrapper ul {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .nav_wrapper ul {
        flex-direction: column;
        align-items: center;
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .nav_wrapper ul {
        flex-direction: column;
        align-items: center;
        width: 100%; } }
  .nav_wrapper li {
    margin-right: 40px; }
    @media only screen and (max-width: 767px) {
      .nav_wrapper li {
        margin-right: 0;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .nav_wrapper li {
        margin-right: 0;
        margin-bottom: 20px; } }
    .nav_wrapper li.current-menu-item a:before {
      width: 100%; }
  .nav_wrapper a {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #5f5655;
    text-decoration: none;
    position: relative; }
    .nav_wrapper a:before {
      content: '';
      width: 0;
      height: 1px;
      background-color: #5f5655;
      position: absolute;
      bottom: -3px;
      left: 0;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
    .nav_wrapper a:hover:before {
      width: 100%; }

.search_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-image: url("./images/search-bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  overflow: hidden;
  opacity: 0; }
  .search_wrapper.is--active {
    visibility: visible;
    overflow: visible;
    opacity: 1; }
  .search_wrapper:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0;
    top: 0; }
  .search_wrapper span {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 30px;
    right: 30px;
    background: url("./images/icon-close.svg") no-repeat center -21px;
    cursor: pointer; }
  .search_wrapper__content {
    position: relative;
    z-index: 3; }
    @media only screen and (max-width: 767px) {
      .search_wrapper__content {
        width: 100%;
        padding: 0 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 990px) {
      .search_wrapper__content {
        width: 100%;
        padding: 0 15px; } }
    .search_wrapper__content input {
      background-color: transparent;
      outline: 0;
      border: 0;
      border-bottom: 1px solid #fafbfb;
      padding-bottom: 5px;
      font-family: 'Gilroy', sans-serif;
      font-size: 38px;
      color: #fff;
      background-image: url("./images/icon-loop.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 30px; }
      .search_wrapper__content input::-webkit-input-placeholder {
        font-family: 'Gilroy', sans-serif;
        font-size: 38px;
        color: #fff; }
      .search_wrapper__content input:-moz-placeholder {
        font-family: 'Gilroy', sans-serif;
        font-size: 38px;
        color: #fff; }
      .search_wrapper__content input::-moz-placeholder {
        font-family: 'Gilroy', sans-serif;
        font-size: 38px;
        color: #fff; }
      .search_wrapper__content input:-ms-input-placeholder {
        font-family: 'Gilroy', sans-serif;
        font-size: 38px;
        color: #fff; }
      @media only screen and (max-width: 767px) {
        .search_wrapper__content input {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        .search_wrapper__content input {
          width: 100%; } }

.hamburger {
  float: right;
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  font-size: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 5;
  background: none;
  top: 5px;
  margin-left: 15px;
  transition: transform 0.2s ease-in; }

.hamburger:focus {
  outline: none; }

.hamburger span {
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  right: 8px;
  height: 3px;
  background: #fff; }

.hamburger span::before,
.hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
  content: ''; }

.hamburger span::before {
  top: 10px; }

.hamburger span::after {
  bottom: -20px; }

.hamburger span {
  background: #000;
  transition: all 0s 0.3s; }

.hamburger span::before,
.hamburger span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s; }

.hamburger span::before {
  transition-property: top, transform; }

.hamburger span::after {
  transition-property: bottom, transform; }

.header_navigation__icon {
  display: none !important; }

/* active state, i.e. menu open */
.hamburger.toggled span {
  background: none;
  top: 12px; }

.hamburger.toggled span::before {
  top: 0;
  transform: rotate(45deg); }

.hamburger.toggled span::after {
  bottom: 0;
  transform: rotate(-45deg); }

.hamburger.toggled span::before,
.hamburger.toggled span::after {
  transition-delay: 0s, 0.3s; }

.home .main_footer::after {
  display: none; }

.home .footer_wrapper {
  background-color: #fafbfb; }

.home .footer_title h3 {
  color: #000000; }

.home .footer_content p {
  color: #5f5655; }

.home .footer_content a {
  color: #5f5655; }

.home .footer_content .input_email {
  border: 1px solid #000000;
  background-color: #fafbfb;
  color: #000; }
  .home .footer_content .input_email::-webkit-input-placeholder {
    color: #5f5655; }
  .home .footer_content .input_email:-moz-placeholder {
    color: #5f5655; }
  .home .footer_content .input_email::-moz-placeholder {
    color: #5f5655; }
  .home .footer_content .input_email:-ms-input-placeholder {
    color: #5f5655; }

.home .footer_content .submit_button {
  background: url("./images/icon-submit.svg") no-repeat center #000; }

.home .footer_bottom {
  border-top: 1px solid #5f5655; }

.home .footer_copyright p {
  color: #5f5655; }

.main_footer {
  width: 100%;
  background-color: #0f0d0d;
  position: relative; }
  .main_footer::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: -1;
    background-image: url("./images/footer-bg.svg");
    background-repeat: no-repeat;
    background-position: left top; }

.footer_wrapper {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 116px; }
  @media only screen and (max-width: 767px) {
    .footer_wrapper {
      padding: 0px 15px; } }

.footer_title {
  display: flex; }
  .footer_title h3 {
    font-size: 27px;
    margin-bottom: 44px;
    font-family: 'Gilroy', sans-serif;
    margin-top: 120px;
    color: #fafbfb; }
    @media only screen and (max-width: 767px) {
      .footer_title h3 {
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 50px; } }

.footer_content {
  margin-bottom: 120px; }
  @media only screen and (max-width: 767px) {
    .footer_content {
      margin-bottom: 0; } }
  .footer_content p {
    font-weight: 100;
    font-size: 15px;
    color: #fafbfb;
    line-height: 1.2em; }
  .footer_content li {
    margin-bottom: 20px; }
  .footer_content a {
    text-decoration: none;
    color: #fafbfb; }
  .footer_content form {
    display: flex;
    margin-bottom: 30px;
    position: relative; }
  .footer_content .input_email {
    width: 100%;
    border: 1px solid #fafbfb;
    padding: 16px 15px 14px;
    margin: 0;
    background-color: #0f0d0d;
    outline: 0;
    font-size: 15px;
    color: #fafbfb; }
    .footer_content .input_email::-webkit-input-placeholder {
      font-size: 15px;
      color: #fafbfb; }
    .footer_content .input_email:-moz-placeholder {
      font-size: 15px;
      color: #fafbfb; }
    .footer_content .input_email::-moz-placeholder {
      font-size: 15px;
      color: #fafbfb; }
    .footer_content .input_email:-ms-input-placeholder {
      font-size: 15px;
      color: #fafbfb; }
  .footer_content .submit_button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border: 0;
    outline: 0;
    background: url("./images/icon-submit-black.svg") no-repeat center #fff;
    cursor: pointer; }

.footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid #fafbfb; }

.footer_copyright {
  display: flex; }
  .footer_copyright img {
    width: 12px;
    margin-right: 5px; }
  .footer_copyright p {
    font-weight: 100;
    font-size: 15px;
    line-height: 1.2em;
    color: #fafbfb;
    margin-left: 2px; }

.footer_socials {
  display: flex;
  justify-content: flex-end; }
  .footer_socials a {
    padding-left: 15px; }
  .footer_socials img {
    height: 16px; }

.m01 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: calc(100vh - 85px);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .m01:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2; }
  .m01 .m01-slider {
    width: 100%;
    height: calc(100vh - 85px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
    .m01 .m01-slider .m01-slide {
      width: 100%;
      height: calc(100vh - 85px);
      float: left;
      outline: 0; }
  .m01__content {
    color: #ffffff;
    text-align: center;
    position: relative;
    z-index: 4; }
    @media only screen and (max-width: 767px) {
      .m01__content {
        padding: 0 15px; } }
    .m01__content h4 {
      font-family: 'Gilroy';
      font-size: 28px;
      line-height: 1.21429em;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .m01__content h4 {
          font-size: 18px;
          line-height: 1.33333em; } }
    .m01__content h1 {
      font-size: 54px;
      font-family: 'Gilroy';
      font-weight: 800;
      line-height: 1.14815em; }
      @media only screen and (max-width: 767px) {
        .m01__content h1 {
          font-size: 38px;
          line-height: 1.15789em; } }

.m02 {
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  padding: 140px 15px; }
  @media only screen and (max-width: 767px) {
    .m02 {
      padding: 80px 15px; } }
  .m02__main {
    display: flex;
    margin-left: 54px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .m02__main {
        margin-left: 0px;
        flex-wrap: wrap; } }
    .m02__main_left {
      width: 50%;
      padding-right: 80px; }
      @media only screen and (max-width: 767px) {
        .m02__main_left {
          width: 100%;
          padding-right: 0px;
          margin-bottom: 20px; } }
      .m02__main_left h2 {
        font-size: 40px;
        color: #000000;
        font-family: 'Gilroy', sans-serif;
        margin-bottom: 60px; }
        @media only screen and (max-width: 767px) {
          .m02__main_left h2 {
            font-size: 32px;
            line-height: 1.375em; } }
      .m02__main_left p {
        font-size: 20px;
        line-height: 1.3em;
        margin-bottom: 50px; }
        @media only screen and (max-width: 767px) {
          .m02__main_left p {
            font-size: 18px;
            line-height: 1.33333em; } }
      .m02__main_left a {
        text-decoration: none;
        color: #000000;
        font-size: 20px;
        font-family: 'Gilroy';
        font-weight: bold;
        background-image: url("./images/icon-more.svg");
        background-repeat: no-repeat;
        background-position: right 1px;
        padding-right: 25px; }
    .m02__main_right {
      width: 50%; }
      @media only screen and (max-width: 767px) {
        .m02__main_right {
          width: 100%; } }
      .m02__main_right img {
        object-fit: cover; }
  .m02__bottom {
    width: 100%;
    max-width: max-content;
    display: flex;
    position: relative;
    border: 1px solid rgba(254, 21, 6, 0.25);
    background-color: #fff;
    padding: 30px 70px;
    margin-top: -100px;
    margin-right: 450px;
    margin-left: auto; }
    @media only screen and (max-width: 767px) {
      .m02__bottom {
        padding: 30px 15px 0;
        margin-right: 0; } }
    .m02__bottom a.go-next {
      position: absolute;
      right: 10px;
      top: 35%;
      background-image: url("./images/icon-next.svg");
      background-repeat: no-repeat;
      text-decoration: none;
      height: 50px;
      width: 31px;
      color: transparent; }
    .m02__bottom .row {
      margin-left: -40px;
      margin-right: -40px; }
    .m02__bottom .column-6 {
      padding: 0 40px; }
  .m02__items {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .m02__items {
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 0 25px; } }
  .m02__item__icon {
    height: 70px; }
    @media only screen and (max-width: 767px) {
      .m02__item__icon {
        max-width: 80px; } }
    .m02__item__icon img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .m02__item__content {
    padding-left: 10px; }
    .m02__item__content h3 {
      color: #ff0601;
      font-size: 60px;
      font-family: 'Gilroy', sans-serif;
      font-weight: bold; }
      @media only screen and (max-width: 767px) {
        .m02__item__content h3 {
          text-align: right; } }
    .m02__item__content p {
      font-size: 20px;
      white-space: nowrap; }

.m03 {
  width: 100%;
  background-color: #141111;
  padding-top: 70px;
  padding-bottom: 70px; }
  @media only screen and (max-width: 767px) {
    .m03 {
      padding-top: 50px;
      padding-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px; } }
  .m03__slider {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto; }
  .m03 .main_title {
    color: white; }
  @media only screen and (max-width: 767px) {
    .m03__box {
      margin-bottom: 15px; } }
  .m03__box__image {
    width: 100%;
    height: 250px; }
  .m03__box__content {
    background-color: #fff;
    padding: 40px 20px; }
    .m03__box__content h2 {
      font-family: 'Gilroy', sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 1.16667em;
      margin-bottom: 15px; }
    .m03__box__content p {
      font-family: 'Helvetica Neue';
      font-size: 14px;
      line-height: 1.14286em;
      margin-bottom: 20px; }
    .m03__box__content a {
      font-weight: bold;
      font-size: 14px;
      text-decoration: none;
      color: #000;
      padding-right: 18px;
      background-image: url("./images/icon-more.svg");
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: right 1px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m03__box__content a:hover {
        padding-right: 22px; }

.m04 {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding: 100px 15px; }
  .m04__content {
    margin-bottom: 40px; }
    .m04__content p {
      font-size: 18px;
      line-height: 1.33333em;
      text-align: center; }

.m05 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100vh; }
  @media only screen and (max-width: 767px) {
    .m05 {
      height: 350px; } }
  .m05 a {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center; }

.m06 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 80px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .m06 {
      padding-top: 40px;
      padding-bottom: 50px; } }
  .m06__header {
    margin-top: 100px;
    margin-bottom: 80px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .m06__header {
        margin-top: 50px;
        margin-bottom: 20px; } }
    .m06__header select {
      height: 40px;
      min-width: 170px;
      margin-left: 54px;
      font-size: 15px;
      padding: 10px;
      appearance: none;
      background: url(./images/arrow-bottom.png) 95% center no-repeat !important;
      outline: none; }
      .m06__header select:first-child {
        margin-left: 0; }
      @media only screen and (max-width: 767px) {
        .m06__header select {
          margin-left: 0;
          margin-bottom: 20px; } }
  .m06__apartments {
    padding: 0 30px;
    margin-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .m06__apartments {
        padding: 0 15px;
        margin-bottom: 50px; } }
  .m06__item {
    width: 100%;
    background-color: #fff;
    border: 1px solid #000000;
    margin-top: 30px; }
    .m06__item a {
      color: #000;
      text-decoration: none; }
    .m06__item__image {
      width: 100%;
      height: 350px; }
    .m06__item__content {
      padding: 30px 20px; }
      .m06__item__content p {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: 1.33333em;
        margin-bottom: 15px; }
      .m06__item__content h4 {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: 1.33333em; }
        .m06__item__content h4 b {
          margin-right: 5px; }
      .m06__item__content h5 {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: 1.33333em; }
        .m06__item__content h5 b {
          margin-right: 5px; }
    .m06__item__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px; }
      .m06__item__title_flex {
        display: flex;
        align-items: center; }
      .m06__item__title h3 {
        font-family: 'Gilroy', sans-serif;
        font-size: 22px;
        line-height: 1.18182em;
        font-weight: bold;
        margin-right: 15px; }
      .m06__item__title span {
        font-size: 15px; }
  .m06__button {
    text-align: center; }
    .m06__button a {
      font-size: 20px;
      font-family: 'Gilroy';
      font-weight: bold;
      display: inline-block;
      padding: 20px 55px 18px;
      text-decoration: none;
      color: #f7f7f7;
      background-color: #ff0601; }

.m06__apartments .column-3:nth-child(4n + 1) {
  clear: left; }

.m07 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 100px; }
  .m07 .main_title span {
    font-size: 20px;
    color: #ff0601; }
  .m07 .main_title h3 {
    font-family: 'Gilroy';
    font-weight: 800;
    font-size: 40px;
    line-height: 1.8em; }
  .m07__local_business {
    padding: 50px 170px 100px; }
    @media only screen and (max-width: 767px) {
      .m07__local_business {
        padding-top: 30px;
        padding-right: 15px;
        padding-bottom: 50px;
        padding-left: 15px; } }
  .m07__items {
    width: 300px;
    display: table;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .m07__items {
        max-width: 300px;
        width: 100%;
        margin-bottom: 30px; } }
  .m07__item__image {
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .m07__item__image {
        width: 60%;
        margin: 0 auto; } }
    .m07__item__image img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .m07__item__content {
    text-align: center; }
    .m07__item__content h3 {
      font-size: 50px;
      font-family: 'Gilroy', sans-serif;
      font-weight: bold;
      color: #000000;
      margin-top: 50px;
      margin-bottom: 50px; }
      @media only screen and (max-width: 767px) {
        .m07__item__content h3 {
          font-size: 40px;
          margin-top: 20px;
          margin-bottom: 20px; } }
    .m07__item__content a {
      display: inline-block;
      text-decoration: none;
      font-size: 20px;
      color: #000000;
      border: 1px solid #707070;
      background-color: #ffffff;
      padding: 16px 57px;
      transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1); }
      .m07__item__content a:hover {
        background-color: #000000;
        color: #fff; }

.m08 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 15px; }
  @media only screen and (max-width: 767px) {
    .m08_wrapper {
      width: 100%;
      padding: 0 15px; } }
  .m08_wrapper__inner {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .m08_wrapper__inner {
        margin-bottom: 50px; } }
  .m08__slider {
    height: 500px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .m08__slider {
        height: 350px; } }
    .m08__slider__image {
      width: 100%;
      height: 500px;
      float: left;
      outline: 0; }
      @media only screen and (max-width: 767px) {
        .m08__slider__image {
          height: 350px; } }
    .m08__slider__thumbs {
      width: 100%; }
      .m08__slider__thumbs .slick-track {
        margin: 0 -8px; }
      .m08__slider__thumbs__image {
        float: left;
        height: 160px;
        padding: 0 8px;
        outline: 0;
        cursor: pointer; }
        @media only screen and (max-width: 767px) {
          .m08__slider__thumbs__image {
            height: 130px; } }
  .m08__description {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 30px; }
    .m08__description p {
      width: 50%;
      font-size: 32px;
      line-height: 1.3125em; }
      @media only screen and (max-width: 767px) {
        .m08__description p {
          width: 100%;
          font-size: 24px;
          line-height: 1.25em; } }
  @media only screen and (max-width: 767px) {
    .m08__plan {
      margin-top: 50px; } }
  .m08__plan3d {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
    margin-bottom: 30px;
    text-align: center; }
    .m08__plan3d img {
      max-width: 100%;
      max-height: 100vh; }
    .m08__plan3d .main__button {
      margin-top: 50px; }
      .m08__plan3d .main__button a {
        background-image: none;
        padding-right: 20px; }
  .m08__services {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 1240px; }
  .m08__service {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px; }
    .m08__service__image {
      margin-bottom: 25px; }
    .m08__service__content {
      color: #000; }
      .m08__service__content h4 {
        font-family: 'Gilroy', sans-serif;
        font-size: 26px;
        line-height: 1.30769em;
        margin-bottom: 5px; }
      .m08__service__content p {
        font-size: 16px;
        line-height: 1.375em;
        text-align: left; }

.m09 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 60px; }
  .m09__local_business {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 20px;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media only screen and (max-width: 767px) {
      .m09__local_business {
        padding: 20px 15px;
        justify-content: center; } }
  .m09__item {
    max-width: 300px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px; }
    .m09__item__image {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 15px; }
    .m09__item__content h3 {
      font-size: 22px;
      line-height: 1.27273em;
      margin-bottom: 20px; }
    .m09__item__content h5 {
      font-size: 18px;
      line-height: 1.22222em;
      color: #5f5655; }

.m10 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 15px; }
  .m10__form {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 20px;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media only screen and (max-width: 767px) {
      .m10__form {
        padding: 20px 15px; } }
  .m10 .wpcf7-submit {
    margin: 30px auto 0;
    display: block; }

.m11 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 100px; }
  @media only screen and (max-width: 767px) {
    .m11 {
      margin-top: 50px; } }
  .m11_wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 20px; }
    @media only screen and (max-width: 767px) {
      .m11_wrapper {
        padding: 20px 15px 0; } }
    .m11_wrapper img {
      max-width: 100%;
      object-fit: contain;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px; }
    .m11_wrapper h3 {
      font-family: 'Gilroy', sans-serif;
      font-size: 26px;
      line-height: 1.23077em;
      margin-bottom: 40px;
      text-align: center; }

.m12 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 70px; }
  @media only screen and (max-width: 767px) {
    .m12 {
      margin-top: 30px; } }
  .m12_wrapper {
    margin-top: 90px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 767px) {
      .m12_wrapper {
        padding: 0 15px;
        margin-top: 30px;
        margin-bottom: 50px; } }
    .m12_wrapper__image {
      width: 100%;
      padding-right: 80px; }
      @media only screen and (max-width: 767px) {
        .m12_wrapper__image {
          padding-right: 0px;
          margin-bottom: 20px; } }
      .m12_wrapper__image img {
        width: 100%; }
        @media only screen and (max-width: 767px) {
          .m12_wrapper__image img {
            height: 320px;
            object-fit: cover; } }
  .m12 p {
    font-size: 18px;
    line-height: 1.44444em;
    margin-bottom: 20px; }

.m13 {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .m13 {
      margin-top: 30px;
      margin-bottom: 50px; } }
  .m13_wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 50px; }
    @media only screen and (max-width: 767px) {
      .m13_wrapper {
        padding: 0 15px;
        padding-top: 30px; } }
    .m13_wrapper img {
      float: right;
      height: 500px;
      padding-right: 80px; }
      @media only screen and (max-width: 767px) {
        .m13_wrapper img {
          width: 100%;
          height: 320px;
          padding-right: 0;
          object-fit: cover;
          margin-bottom: 30px; } }

.m14 {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 140px; }
  @media only screen and (max-width: 767px) {
    .m14 {
      margin-top: 100px; } }
  .m14_box {
    width: 100%;
    display: flex;
    margin-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .m14_box {
        flex-wrap: wrap;
        margin-bottom: 50px; } }
    .m14_box--right {
      flex-direction: row-reverse;
      text-align: right; }
      .m14_box--right .m14_box__image {
        margin-right: 0;
        margin-left: 100px; }
        @media only screen and (max-width: 767px) {
          .m14_box--right .m14_box__image {
            margin-left: 0; } }
    .m14_box__image {
      width: 240px;
      margin-right: 100px; }
      @media only screen and (max-width: 767px) {
        .m14_box__image {
          width: 40%;
          text-align: center;
          margin-bottom: 15px;
          margin-right: 0; } }
      .m14_box__image img {
        width: 100%;
        object-fit: contain; }
    .m14_box__content {
      width: calc(100% - 340px);
      color: #000; }
      @media only screen and (max-width: 767px) {
        .m14_box__content {
          width: 100%; } }
      .m14_box__content h3 {
        font-family: 'Gilroy', sans-serif;
        font-weight: bold;
        font-size: 42px;
        line-height: 1.2381em;
        margin-bottom: 20px; }
        @media only screen and (max-width: 767px) {
          .m14_box__content h3 {
            font-size: 32px;
            line-height: 1.375em; } }
      .m14_box__content p {
        font-size: 18px;
        line-height: 1.33333em; }

.m15 {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 140px; }
  .m15__slider {
    height: 500px;
    margin-bottom: 20px; }
    .m15__slider__image {
      width: 100%;
      height: 500px;
      float: left;
      outline: 0; }
    .m15__slider__thumbs {
      width: 100%; }
      .m15__slider__thumbs .slick-track {
        margin: 0 -8px; }
      .m15__slider__thumbs__image {
        float: left;
        height: 160px;
        padding: 0 8px;
        outline: 0;
        cursor: pointer; }

.m16 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 80px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .m16 {
      padding-top: 40px;
      padding-bottom: 50px; } }
  .m16__header {
    margin-top: 100px;
    margin-bottom: 80px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .m16__header {
        margin-top: 50px;
        margin-bottom: 20px; } }
    .m16__header select {
      height: 40px;
      min-width: 170px;
      margin-left: 54px;
      font-size: 15px;
      padding: 10px;
      appearance: none;
      background: url(./images/arrow-bottom.png) 95% center no-repeat !important;
      outline: none; }
      .m16__header select:first-child {
        margin-left: 0; }
      @media only screen and (max-width: 767px) {
        .m16__header select {
          margin-left: 0;
          margin-bottom: 20px; } }
  .m16__apartments {
    padding: 0 30px;
    margin-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .m16__apartments {
        padding: 0 15px;
        margin-bottom: 50px; } }
  .m16__item {
    width: 100%;
    background-color: #fff;
    border: 1px solid #000000;
    margin-top: 30px; }
    .m16__item a {
      color: #000;
      text-decoration: none; }
    .m16__item__image {
      width: 100%;
      height: 350px; }
    .m16__item__content {
      padding: 30px 20px; }
      .m16__item__content p {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: 1.33333em;
        margin-bottom: 15px; }
      .m16__item__content h4 {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: 1.33333em; }
        .m16__item__content h4 b {
          margin-right: 5px; }
      .m16__item__content h5 {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: 1.33333em; }
        .m16__item__content h5 b {
          margin-right: 5px; }
    .m16__item__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px; }
      .m16__item__title_flex {
        display: flex;
        align-items: center; }
      .m16__item__title h3 {
        font-family: 'Gilroy', sans-serif;
        font-size: 22px;
        line-height: 1.18182em;
        font-weight: bold;
        margin-right: 15px; }
      .m16__item__title span {
        font-size: 15px; }
  .m16__button {
    text-align: center; }
    .m16__button a {
      font-size: 20px;
      font-family: 'Gilroy';
      font-weight: bold;
      display: inline-block;
      padding: 20px 55px 18px;
      text-decoration: none;
      color: #f7f7f7;
      background-color: #ff0601; }

.m16__apartments .column-3:nth-child(4n + 1) {
  clear: left; }

.m17 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 80px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .m17 {
      padding-top: 40px;
      padding-bottom: 50px; } }
  .m17__header {
    margin-top: 100px;
    margin-bottom: 80px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .m17__header {
        margin-top: 50px;
        margin-bottom: 20px; } }
  .m17__description {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: block;
    margin-bottom: 150px; }
    @media only screen and (max-width: 767px) {
      .m17__description {
        margin-bottom: 100px; } }
    .m17__description p {
      font-family: 'Gilroy';
      font-size: 24px;
      line-height: 1.33333em;
      text-align: justify;
      margin-bottom: 30px; }
      @media only screen and (max-width: 767px) {
        .m17__description p {
          font-size: 20px;
          line-height: 1.4em;
          margin-bottom: 20px; } }
  .m17__parkings {
    width: 100%; }
  .m17__parking {
    width: 100%;
    margin-bottom: 70px; }
    .m17__parking h2 {
      font-family: 'Gilroy', sans-serif;
      font-size: 38px;
      line-height: 1.21053em;
      margin-bottom: 40px;
      text-transform: uppercase;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .m17__parking h2 {
          font-size: 26px;
          line-height: 1.15385em; } }
    .m17__parking img {
      width: 100%; }

.m18 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 80px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .m18 {
      padding-top: 40px;
      padding-bottom: 50px; } }
  .m18__header {
    margin-top: 100px;
    margin-bottom: 80px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .m18__header {
        margin-top: 50px;
        margin-bottom: 20px; } }
  .m18__images {
    width: 100%; }
  .m18__image {
    width: 100%;
    margin-bottom: 20px; }
    .m18__image img {
      width: 100%; }

.nanogallery_gallerytheme_dark_m18__images .nGY2GThumbnail {
  border-color: #fff !important;
  background: #fff !important; }
