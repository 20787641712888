.m13 {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
  @include mobile {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  &_wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 50px;
    @include mobile {
      padding: 0 15px;
      padding-top: 30px;
    }
    img {
      float: right;
      height: 500px;
      padding-right: 80px;
      @include mobile {
        width: 100%;
        height: 320px;
        padding-right: 0;
        object-fit: cover;
        margin-bottom: 30px;
      }
    }
  }
}
