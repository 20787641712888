.m16 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 80px;
  padding-bottom: 100px;
  @include mobile {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  &__header {
    margin-top: 100px;
    margin-bottom: 80px;
    text-align: center;
    @include mobile {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    select {
      height: 40px;
      min-width: 170px;
      margin-left: 54px;
      font-size: 15px;
      padding: 10px;
      appearance: none;
      background: url(./images/arrow-bottom.png) 95% center no-repeat !important;
      outline: none;
      &:first-child {
        margin-left: 0;
      }
      @include mobile {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }
  &__apartments {
    padding: 0 30px;
    margin-bottom: 100px;
    @include mobile {
      padding: 0 15px;
      margin-bottom: 50px;
    }
  }
  &__item {
    width: 100%;
    background-color: #fff;
    border: 1px solid #000000;
    margin-top: 30px;
    a {
      color: #000;
      text-decoration: none;
    }
    &__image {
      width: 100%;
      height: 350px;
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      padding: 30px 20px;
      p {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: em(24, 18);
        margin-bottom: 15px;
      }
      h4 {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: em(24, 18);
        b {
          margin-right: 5px;
        }
      }
      h5 {
        font-family: 'Gilroy';
        font-size: 18px;
        line-height: em(24, 18);
        b {
          margin-right: 5px;
        }
      }
    }
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      &_flex {
        display: flex;
        align-items: center;
      }
      h3 {
        font-family: 'Gilroy', sans-serif;
        font-size: 22px;
        line-height: em(26, 22);
        font-weight: bold;
        margin-right: 15px;
      }
      span {
        font-size: 15px;
      }
    }
  }
  &__button {
    text-align: center;
    a {
      font-size: 20px;
      font-family: 'Gilroy';
      font-weight: bold;
      display: inline-block;
      padding: 20px 55px 18px;
      text-decoration: none;
      color: #f7f7f7;
      background-color: #ff0601;
    }
  }
}
.m16__apartments .column-3:nth-child(4n + 1) {
  clear: left;
}
