.m01 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: calc(100vh - 85px);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .m01-slider {
    width: 100%;
    height: calc(100vh - 85px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .m01-slide {
      width: 100%;
      height: calc(100vh - 85px);
      float: left;
      outline: 0;
    }
    img {
      @extend %imgCropped;
    }
  }
  &__content {
    color: #ffffff;
    text-align: center;
    position: relative;
    z-index: 4;
    @include mobile {
      padding: 0 15px;
    }

    h4 {
      font-family: 'Gilroy';
      font-size: 28px;
      line-height: em(34, 28);
      margin-bottom: 20px;
      @include mobile {
        font-size: 18px;
        line-height: em(24, 18);
      }
    }
    h1 {
      font-size: 54px;
      font-family: 'Gilroy';
      font-weight: 800;
      line-height: em(62, 54);
      @include mobile {
        font-size: 38px;
        line-height: em(44, 38);
      }
    }
  }
}
