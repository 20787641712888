.m15 {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 140px;
  &__slider {
    height: 500px;
    margin-bottom: 20px;
    &__image {
      width: 100%;
      height: 500px;
      float: left;
      outline: 0;
      img {
        @extend %imgCropped;
      }
    }
    &__thumbs {
      width: 100%;
      .slick-track {
        margin: 0 -8px;
      }
      &__image {
        float: left;
        height: 160px;
        padding: 0 8px;
        outline: 0;
        cursor: pointer;
        img {
          @extend %imgCropped;
        }
      }
    }
  }
}
