.m02 {
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  padding: 140px 15px;
  @include mobile {
    padding: 80px 15px;
  }

  &__main {
    display: flex;
    margin-left: 54px;
    position: relative;
    @include mobile {
      margin-left: 0px;
      flex-wrap: wrap;
    }
    &_left {
      width: 50%;
      padding-right: 80px;
      @include mobile {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 40px;
        color: #000000;
        font-family: 'Gilroy', sans-serif;
        margin-bottom: 60px;
        @include mobile {
          font-size: 32px;
          line-height: em(44, 32);
        }
      }
      p {
        font-size: 20px;
        line-height: 1.3em;
        margin-bottom: 50px;
        @include mobile {
          font-size: 18px;
          line-height: em(24, 18);
        }
      }
      a {
        text-decoration: none;
        color: #000000;
        font-size: 20px;
        font-family: 'Gilroy';
        font-weight: bold;
        background-image: url('./images/icon-more.svg');
        background-repeat: no-repeat;
        background-position: right 1px;
        padding-right: 25px;
      }
    }
    &_right {
      width: 50%;
      @include mobile {
        width: 100%;
      }
      img {
        object-fit: cover;
      }
    }
  }
  &__bottom {
    width: 100%;
    max-width: max-content;
    display: flex;
    position: relative;
    border: 1px solid rgba($color: #fe1506, $alpha: 0.25);
    background-color: #fff;
    padding: 30px 70px;
    margin-top: -100px;
    margin-right: 450px;
    margin-left: auto;
    @include mobile {
      padding: 30px 15px 0;
      margin-right: 0;
    }
    a.go-next {
      position: absolute;
      right: 10px;
      top: 35%;
      background-image: url('./images/icon-next.svg');
      background-repeat: no-repeat;
      text-decoration: none;
      height: 50px;
      width: 31px;
      color: transparent;
    }
    .row {
      margin-left: -40px;
      margin-right: -40px;
    }
    .column-6 {
      padding: 0 40px;
    }
  }
  &__items {
    display: flex;
    align-items: center;
    @include mobile {
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 0 25px;
    }
  }
  &__item {
    &__icon {
      height: 70px;
      @include mobile {
        max-width: 80px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__content {
      padding-left: 10px;
      h3 {
        color: #ff0601;
        font-size: 60px;
        font-family: 'Gilroy', sans-serif;
        font-weight: bold;
        @include mobile {
          text-align: right;
        }
      }
      p {
        font-size: 20px;
        white-space: nowrap;
      }
    }
  }
}
