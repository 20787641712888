.m05 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100vh;
  @include mobile {
    height: 350px;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}
