.headerWrap {
  float: none;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #fafbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  @include mobile {
    padding: 10px 15px;
  }
  @include tablet {
    padding: 10px 15px;
  }
}
.main_content {
  margin-top: 86px;
  @include mobile {
    margin-top: 76px;
  }
  @include tablet {
    margin-top: 76px;
  }
}
.main_header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  z-index: 99;
  &__logo {
    width: 100px;
  }
  &__right {
    display: flex;
    @include mobile {
      align-items: center;
    }
    @include tablet {
      align-items: center;
    }
  }
  &__right {
    img {
      width: 20px;
    }
  }
}
.nav_wrapper {
  display: flex;
  align-items: center;
  &.is--toggled {
    @include mobile {
      display: block;
    }
    @include tablet {
      display: block;
    }
  }
  @include mobile {
    display: none;
    position: fixed;
    top: 76px;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 4;
    padding: 70px 15px;
    border-top: 1px solid #000;
  }
  @include tablet {
    display: none;
    position: fixed;
    top: 76px;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 4;
    padding: 70px 15px;
    border-top: 1px solid #000;
  }
  ul {
    display: flex;
    @include mobile {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    @include tablet {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  li {
    margin-right: 40px;
    @include mobile {
      margin-right: 0;
      margin-bottom: 20px;
    }
    @include tablet {
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.current-menu-item {
      a {
        &:before {
          width: 100%;
        }
      }
    }
  }
  a {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #5f5655;
    text-decoration: none;
    position: relative;
    &:before {
      content: '';
      width: 0;
      height: 1px;
      background-color: #5f5655;
      position: absolute;
      bottom: -3px;
      left: 0;
      transition: $transition1;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
}

.search_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-image: url('./images/search-bg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  &.is--active {
    visibility: visible;
    overflow: visible;
    opacity: 1;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: absolute;
    left: 0;
    top: 0;
  }
  span {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 30px;
    right: 30px;
    background: url('./images/icon-close.svg') no-repeat center -21px;
    cursor: pointer;
  }
  &__content {
    position: relative;
    z-index: 3;
    @include mobile {
      width: 100%;
      padding: 0 15px;
    }
    @include tablet {
      width: 100%;
      padding: 0 15px;
    }
    input {
      background-color: transparent;
      outline: 0;
      border: 0;
      border-bottom: 1px solid #fafbfb;
      padding-bottom: 5px;
      font-family: 'Gilroy', sans-serif;
      font-size: 38px;
      color: #fff;
      background-image: url('./images/icon-loop.svg');
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 30px;
      @include placeholder {
        font-family: 'Gilroy', sans-serif;
        font-size: 38px;
        color: #fff;
      }
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
    }
  }
}
.hamburger {
  float: right;
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  font-size: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 5;
  background: none;
  top: 5px;
  margin-left: 15px;
  transition: transform 0.2s ease-in;
}

.hamburger:focus {
  outline: none;
}

.hamburger span {
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  right: 8px;
  height: 3px;
  background: #fff;
}

.hamburger span::before,
.hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
  content: '';
}

.hamburger span::before {
  top: 10px;
}

.hamburger span::after {
  bottom: -20px;
}

.hamburger span {
  background: #000;
  transition: all 0s 0.3s;
}

.hamburger span::before,
.hamburger span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}

.hamburger span::before {
  transition-property: top, transform;
}

.hamburger span::after {
  transition-property: bottom, transform;
}

.header_navigation__icon {
  display: none !important;
}

/* active state, i.e. menu open */

.hamburger.toggled span {
  background: none;
  top: 12px;
}

.hamburger.toggled span::before {
  top: 0;
  transform: rotate(45deg);
}

.hamburger.toggled span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.hamburger.toggled span::before,
.hamburger.toggled span::after {
  transition-delay: 0s, 0.3s;
}
