body {
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  background-color: #fafbfb;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
option {
  font-family: 'Gilroy', sans-serif;
  opacity: 1;
  @include placeholder {
    font-family: 'Gilroy', sans-serif;
    opacity: 1;
    font-size: 14px;
    color: black;
  }
}
.main_title {
  text-align: center;
  margin-bottom: 40px;
  @include mobile {
    margin-bottom: 25px;
  }
  h3 {
    font-family: 'Gilroy', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: em(48, 42);
    position: relative;
    padding-bottom: 30px;
    @include mobile {
      font-size: 32px;
      line-height: em(40, 32);
      padding-bottom: 15px;
    }
  }
  h3:after {
    content: '';
    width: 130px;
    height: 2px;
    background: #ff0601;
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    @include mobile {
      width: 90px;
    }
  }
  span {
    display: inline-block;
    font-size: 22px;
    line-height: em(28, 22);
    text-transform: uppercase;
    color: #ff0601;
    margin-bottom: 10px;
  }
}
.main__button {
  text-align: center;
  a {
    display: inline-block;
    padding: 14px 40px 10px 20px;

    font-family: 'Gilroy';
    font-size: 18px;
    line-height: em(24, 18);
    font-weight: bold;
    color: #fafbfb;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #ff0601;
    background-image: url('./images/icon-swipe.svg');
    background-repeat: no-repeat;
    background-position: center right 24px;
    transition: $transition1;
    &:hover {
      background-color: darken(#ff0601, 5%);
    }
  }
}

.formInput {
  width: 100%;
  height: 50px;
  border: 1px solid #000000;
  outline: 0;
  appearance: none;
  padding: 15px;
  font-size: 18px;
  line-height: em(24, 18);
  color: #000;
  background-color: #fafbfb;
  @include placeholder {
    font-size: 18px;
    line-height: em(24, 18);
    color: #5f5655;
  }
}
.formTextarea {
  width: 100%;
  border: 1px solid #000000;
  outline: 0;
  appearance: none;
  padding: 15px;
  font-size: 18px;
  line-height: em(24, 18);
  color: #000;
  background-color: #fafbfb;
  @include placeholder {
    font-size: 18px;
    line-height: em(24, 18);
    color: #5f5655;
  }
}
.wpcf7-form-control-wrap {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
.wpcf7-not-valid-tip {
  margin-top: 5px;
}
.wpcf7-submit {
  appearance: none;
  display: inline-block;
  padding: 14px 20px 10px;
  min-width: 200px;

  font-family: 'Gilroy';
  font-size: 18px;
  line-height: em(24, 18);
  font-weight: bold;
  color: #fafbfb;
  text-align: center;
  background-color: #ff0601;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: $transition1;
  &:hover {
    background-color: darken(#ff0601, 5%);
  }
}

.wpcf7 form .wpcf7-response-output {
  margin: 0;
  margin-top: 20px;
}
