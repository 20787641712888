@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-ExtraBold.woff2") format("woff2"),
    url("./fonts/Gilroy-ExtraBold.woff") format("woff"),
    url("./fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica-Bold.woff2") format("woff2"),
    url("./fonts/Helvetica-Bold.woff") format("woff"),
    url("./fonts/Helvetica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Light.woff2") format("woff2"),
    url("./fonts/Gilroy-Light.woff") format("woff"),
    url("./fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Regular.woff2") format("woff2"),
    url("./fonts/Gilroy-Regular.woff") format("woff"),
    url("./fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./fonts/HelveticaNeue-UltraLight.woff2") format("woff2"),
    url("./fonts/HelveticaNeue-UltraLight.woff") format("woff"),
    url("./fonts/HelveticaNeue-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/HelveticaNeueRegular.woff2") format("woff2"),
    url("./fonts/HelveticaNeueRegular.woff") format("woff"),
    url("./fonts/HelveticaNeueRegular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Bold.woff2") format("woff2"),
    url("./fonts/Gilroy-Bold.woff") format("woff"),
    url("./fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Walbaum";
  src: url("./fonts/Walbaum-Bold.woff2") format("woff2"),
    url("./fonts/Walbaum-Bold.woff") format("woff"),
    url("./fonts/Walbaum-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
