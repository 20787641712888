.m12 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 70px;
  @include mobile {
    margin-top: 30px;
  }

  &_wrapper {
    margin-top: 90px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
    @include mobile {
      padding: 0 15px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    &__image {
      width: 100%;
      padding-right: 80px;
      @include mobile {
        padding-right: 0px;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        @include mobile {
          height: 320px;
          object-fit: cover;
        }
      }
    }
  }

  p {
    font-size: 18px;
    line-height: em(26, 18);
    margin-bottom: 20px;
  }
}
