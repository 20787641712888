.m07 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: block;
  padding-top: 100px;
  .main_title {
    span {
      font-size: 20px;
      color: #ff0601;
    }
    h3 {
      font-family: 'Gilroy';
      font-weight: 800;
      font-size: 40px;
      line-height: 1.8em;
    }
  }
  &__local_business {
    padding: 50px 170px 100px;
    @include mobile {
      padding-top: 30px;
      padding-right: 15px;
      padding-bottom: 50px;
      padding-left: 15px;
    }
  }
  &__items {
    width: 300px;
    display: table;
    margin: 0 auto;
    @include mobile {
      max-width: 300px;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  &__item__image {
    width: 100%;
    @include mobile {
      width: 60%;
      margin: 0 auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__item__content {
    text-align: center;
    h3 {
      font-size: 50px;
      font-family: 'Gilroy', sans-serif;
      font-weight: bold;
      color: #000000;
      margin-top: 50px;
      margin-bottom: 50px;
      @include mobile {
        font-size: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    a {
      display: inline-block;
      text-decoration: none;
      font-size: 20px;
      color: #000000;
      border: 1px solid #707070;
      background-color: #ffffff;
      padding: 16px 57px;
      transition: $transition1;
      &:hover {
        background-color: #000000;
        color: #fff;
      }
    }
  }
}
