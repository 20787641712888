.m09 {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 60px;

  &__local_business {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 20px;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include mobile {
      padding: 20px 15px;
      justify-content: center;
    }
  }
  &__item {
    max-width: 300px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    &__image {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 15px;
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      h3 {
        font-size: 22px;
        line-height: em(28, 22);
        margin-bottom: 20px;
      }
      h5 {
        font-size: 18px;
        line-height: em(22, 18);
        color: #5f5655;
      }
    }
  }
}
